import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ApiService} from '../services/api.service';
import {FunctionsService} from '../services/functions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  @Input('modal') modal;

  service$: Observable<ApiResponse>;
  email: string;

  constructor(
    private service: ApiService,
    private functions: FunctionsService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }



  emailChange(): void
  {

  }

  forgotPass(inpEmail): void
  {
    inpEmail.style.border = '';
    if (!this.email)
    {
      inpEmail.style.border = '1px solid red';
      this.functions.showAlertError('Erro', 'E-mail é obrigatório!');
      return;
    }

    this.service$ = this.service.forgotPass(this.email);
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    });
  }

}
