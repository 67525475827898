<div class="osahan-profile" *ngIf="!loading">

  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Avaliação do Pedido #{{order.order_id}}</h5>
    <button type="button" class="close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="p-3 rating-review-select-page">

    <p class="mb-3 text-primary">O seu comentário e avaliação ajuda a melhorar nossos produtos e serviços</p>
    <div class="d-flex w-100 align-items-center border-bottom">
      <div class="left">
        <p class="small text-muted m-0">Data</p>
        <h6 class="font-weight-bold m-0 my-1 mr-2 text-dark"> {{order.date_create}} <small>{{order.hour_create}}</small></h6>
      </div>
      <div>
        <p class="small text-muted m-0">Total do pedido</p>
        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{functions.prettyPrice(order.total)}}</h6>
      </div>
      <div class="ml-auto">
        <p class=" small text-muted m-0">Itens</p>
        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.count_cart}}</h6>
      </div>
    </div>

    <div class="d-flex align-items-center my-3">
      <div class="star-rating">
        <div class="d-inline-block h6" [class.error]="erroRating">
          <span class="">Qual sua nota?</span>
          <br>
          <ngb-rating [(rate)]="reviewSend.rating" [starTemplate]="t" [max]="5"></ngb-rating>
          <ng-template #t let-fill="fill">
                    <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                    </span>
          </ng-template>
          <!--<star-rating
            [starType]="'svg'"
            [rating]="0"
            [size]="'large'"
            (starClickChange)="onClickStar($event)"></star-rating>-->
        </div>
      </div>
    </div>
    <form>
      <div class="form-group" ><label class="form-label small">Fale sobre a sua experiência</label><textarea class="form-control" [class.error]="erroComent" name="review" (keyup)="erroComent = false" [(ngModel)]="reviewSend.review"></textarea></div>
      <div class="form-group mb-0">
        <button
              type="button"
              class="btn btn-primary btn-block"
              (click)="sendReview()"
              [disabled]="disableBtn"
        >
          <div *ngIf="disableBtn" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Enviar avaliação
        </button>
      </div>
    </form>
  </div>

  <div class="modal-footer border-top p-0">
    <div class="col-12 m-1 p-0" (click)="activeModal.close()">
      <button type="button" class="btn btn-block btn-lg" > <i class="feather-x-circle"></i> Fechar</button>
    </div>
  </div>

</div>

<app-loading *ngIf="loading"></app-loading>


