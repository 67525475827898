<div *ngIf="response$ | async; else loading">
  <div class="bg-primary p-3 pb-5 d-flex header-order-detail">
    <h4 class="m-0 text-white">PEDIDO #{{order.order_id}}</h4>
    <a class="hc-nav-back text-white ml-auto" (click)="activeModal.close()"><span><i class="feather-x h5"></i></span></a>
  </div>

  <div class="mx-3 osahan-cart-item-profile bg-white rounded pt-3 px-3 mt-n4 shadow-sm">
    <div class="d-flex progress-status" *ngIf="order.status_code !== 'FNL'; else orderFinish">
      <div class="col text-center"
           [class.status-active]="order.status_code === 'INL'"
           [class.status-off]="order.status_code !== 'INL'"
      >
        <p class="m-0 mb-1 text-center"><i class="feather-loader bg-novo p-1 rounded-circle"></i></p>
        <span>AGUARDANDO CONFIRMAR</span>
      </div>
      <div class="col text-center"
           [class.status-active]="order.status_code === 'ACT'"
           [class.status-off]="order.status_code !== 'ACT'"
      >
        <p class="m-0 mb-1"><i class="feather-clock bg-preparo p-1 rounded-circle"></i></p>
        <span>EM PREPARO</span>
      </div>
      <div class="col text-center"
           [class.status-active]="order.status_code === 'PRT'"
           [class.status-off]="order.status_code !== 'PRT'"
      >
        <p class="m-0 mb-1"><i class="feather-check-circle bg-pronto p-1 rounded-circle"></i></p>
        <span>PEDIDO PRONTO</span>
      </div>
      <div class="col text-center"
           [class.status-active]="order.status_code === 'SAU'"
           [class.status-off]="order.status_code !== 'SAU'"
      >
        <p class="m-0 mb-1"><i class="feather-send bg-entrega p-1 rounded-circle"></i></p>
        <span>SAIU P/ ENTREGA</span>
      </div>
    </div>

    <ng-template #orderFinish>
      <div class="d-flex">
        <div class="col text-center status-actives py-1">
          <p class="m-0 mb-1 text-center"><i class="feather-check bg-finalizado p-1 rounded-circle"></i></p>
          <span>{{order.status}}</span>
        </div>
        <div class="col text-center align-items-center d-flex">
          <p class="small mb-1">Dê sua opnião!</p>
          <a href="javascript:;" class="btn btn-sm btn-outline-success ml-auto small p-1" (click)="openReviewAdd(order.order_id)"> <i class="feather-star font-weight-bold"></i> AVALIAR AGORA</a>
        </div>
      </div>
    </ng-template>
  </div>


  <div class="p-3">
    <div class="d-flex w-100 align-items-center pb-3 mb-3 border-bottom">
      <div class="left mx-2 mr-4">
        <p class="small text-muted m-0">Data</p>
        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.date_create}} <small>{{order.hour_create}}</small></h6>
      </div>
      <div>
        <p class="small text-muted m-0">Total do pedido</p>
        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.total | prettyPrice}}</h6>
      </div>
      <div class="ml-auto">
        <p class=" small text-muted m-0">Itens</p>
        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.count_cart}}</h6>
      </div>
    </div>
    <div class="w-100 pb-3 mb-3 border-bottom" *ngIf="order.order_address[0]">
      <p class="small text-muted m-0">Endereço de entrega</p>
      <h5 class="font-weight-bold m-0 text-dark"><small> {{order.order_address[0].street}}, {{order.order_address[0].number}}</small> </h5>
      <small class="text-muted mb-n1">{{order.order_address[0].bairro}} - {{order.order_address[0].city}} - {{order.order_address[0].state}}</small>
    </div>

    <!--<div class="media menu-list-checkout border-bottom mb-3 pb-3">
      <div class="media-body">
        <h6 class="mb-1">01 - COMBO - Frango defumado com Cream Cheese</h6>
        <p class="price-card text-black mb-0">1 x R$ 24,90</p>
        <small class="d-block text-muted">Pão 3 Queijos, Queijo, Adicional de bacon <span>(R$ 3,50)</span>, Alface</small>
      </div>
    </div>-->
    <div class="media menu-list-checkout  border-bottom mb-3 pb-3" *ngFor="let item of order.order_details">
      <div class="media-body">
        <h6 class="mb-1">{{item.qty}} x {{item.item_name}} <small>{{item.size ? ' - ' + item.size : ''}}</small></h6>
        <p class="price-card text-black mb-0">{{item.discounted_price | prettyPrice}}</p>
        <small class="d-block text-muted" [innerHTML]="item.addon"></small>
      </div>
    </div>

    <p class="mb-1">Sub Total <span class="float-right text-dark">{{order.subtotal | prettyPrice}}</span></p>
    <p class="mb-1" *ngIf="order.delivery_charge > 0">Taxa de entrega<span class="text-info ml-1"><i class="icofont-info-circle"></i></span><span class="float-right text-dark">{{functions.prettyPrice(order.delivery_charge)}}</span></p>
    <p class="mb-1 text-success"*ngIf="order.voucher_code">Descontos<span class="float-right text-success">- {{functions.prettyPrice(order.voucher_amount)}}</span></p>
    <hr>
    <h6 class="font-weight-bold mb-0">TOTAL A PAGAR <span class="float-right">{{order.total | prettyPrice}}</span></h6>
    <hr>
    <p class="my-3 text-muted">
      <span class="text-dark">
        {{order.payment_type}} <br><b>{{order.payment_provider_name}}</b>  {{order.payment_type === 'Dinheiro na entrega' ? '- Troco' + functions.prettyPrice(order.order_change) : ''}}
      </span>
    </p>

  </div>
</div>

<ng-template #loading>

</ng-template>
