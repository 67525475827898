
<div class="login-page bg-light-01" *ngIf="service.service$ | async; loading">

  <div class="modal-header d-block bg-primary p-3 d-flex align-items-center text-white">
      <h4 class="m-0">ENTRAR</h4>
      <a class="ml-auto" (click)="activeModal.close()"><i class="feather-x h4"></i></a>
  </div>

  <form [class.was-validated]="validate">
    <div class="p-4">
      <h2 *ngIf="!visit" class="text-primary my-0">Bem vindo</h2>
      <p *ngIf="!visit" class=" text-50">Faça o login ou cadastre-se para continuar</p>

      <div class="form-group">
        <label class="username">Email</label>
        <input [(ngModel)]="user.username" type="text" placeholder="E-mail / Celular" class="form-control" id="username" name="username" required>
      </div>
      <div class="form-group">
        <label for="password" class="">Senha</label>
        <input [(ngModel)]="passTmp" type="password" placeholder="Digite a senha" class="form-control" id="password" name="password" required>
      </div>
      <button
          class="btn btn-primary btn-lg btn-block"
          (click)="login()"
          [disabled]="btnDisabled"
      >
        <div *ngIf="btnDisabled" class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Entrar
      </button>
      <div class="py-2" *ngIf="merchant.optionsMerchant.enabled_fblogin">
        <button class="btn btn-lg btn-facebook btn-block"><i class="feather-facebook"></i> Conectar com Facebook</button>
      </div>
      <div class="py-2" *ngIf="merchant.optionsMerchant.enabled_google">
        <button class="btn  btn-outline btn-lg btn-google btn-block"><i class="feather-google"></i> Conectar com Google</button>
      </div>

      <div class="mb-2">
        <a href="javascript:;" (click)="openForgotPass()" class="text-decoration-none">
          <p class="text-center mt-3">Esqueci a senha?</p>
        </a>
      </div>

    </div>
    <div class=" bg-white d-flex align-items-center justify-content-center mb-3">
      <a href="javascript:;" (click)="openRegister()">
        <p class="text-center m-0">Não possui conta? <b class="btn btn-small btn-outline-primary">Cadastre-se agora</b> </p>
      </a>
    </div>
  </form>
</div>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
