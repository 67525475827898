import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MenuComponent} from '../../menu/menu.component';
import {MenuService} from '../../menu/menu.service';
import {LoginComponent} from '../../login/login.component';
import {SignupComponent} from '../../signup/signup.component';
import {AddressComponent} from '../../address/address.component';
import {ReviewComponent} from '../../review/review.component';
import {ReviewAddComponent} from '../../review-add/review-add.component';
import {InfoModalComponent} from '../../modals/info-modal/info-modal.component';
import {AddressZipcodeComponent} from '../../address-zipcode/address-zipcode.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
      private modal: NgbModal,
      private menuService: MenuService,
  ) { }

  openItemModal(): void
  {
    this.modal.open(MenuComponent);
  }

  openEditItemModal(catId: string, itemId: string, row: string): void
  {
    this.menuService.openModalEdit(catId, itemId, row);
    this.modal.open(MenuComponent);
  }

  openLogin(): void
  {
    this.modal.open(LoginComponent);
  }

  openSignUp(): void
  {
    this.modal.open(SignupComponent);
  }

  openAddress(): void
  {
    this.modal.open(AddressComponent);
  }

  openReview(): void
  {
    this.modal.open(ReviewComponent);
  }

  openReviewAdd(): void
  {
    this.modal.open(ReviewAddComponent);
  }

  openInfoMerchant(): void
  {
    this.modal.open(InfoModalComponent);
  }

}
