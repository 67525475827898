import { Component, OnInit } from '@angular/core';
import {Order} from '../../class/order';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {FunctionsService} from '../../services/functions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartService} from '../../services/cart.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  id: string;
  order: Order;
  photos: any;
  response$: Observable<ApiResponse>;
  response2$: Observable<ApiResponse>;

  subtotal: number;
  total: number;

  route: string;

  intervalOrder: any;

  loading: boolean;

  constructor(
      private service: ApiService,
      private routeActive: ActivatedRoute,
      public functions: FunctionsService,
      private modalService: NgbModal)
  {
    this.loading = false;
    this.id = this.routeActive.snapshot.params[`id`];
    if (this.functions.getItemLocal('orderID'))
    {
      this.id = this.functions.getItemLocal('orderID');
    }
  }

  ngOnInit(): void {

    this.order = new Order();
    this.subtotal = 0;
    this.total = 0;
    this.getPhotoItems();
    this.loadOrderInfo();
  }

  loadOrderInfo(): void
  {
    if (this.id)
    {
      this.response$ = this.service.getOrderById(this.id);
      this.loading = true;
      this.response$.subscribe(data => {
        console.log(data);
        if (data.code === 1)
          {
            this.order = data.details.data;
            console.log(this.order);
            console.log(JSON.parse(this.order.json_details));
            if (this.order.voucher_amount)
            {
              this.order.total = (Number(this.order.total) - Number(this.order.voucher_amount)).toString();
            }
          }
        }, error => {this.functions.log(error); },
        () => {
          this.getTotal();
          this.loading = false;
          if (this.order.status_code !== 'FNL')
          {
            this.intervalOrder = setInterval(() => {
              this.orderActive();
            }, 20000);
          }
        });
    }
  }

  orderActive(): void
  {
    this.functions.log('order active');
    let active = true;
    this.response2$ = this.service.getOrderById(this.id);
    this.response2$.subscribe(data => {
      if (data.code === 1)
      {
        if (this.order.status_code === 'FNL')
        {
          active = false;
        }
        this.order.status_code = data.details.data.status_code;
        this.order.status = data.details.data.status;
      }
    }, error => {
      this.functions.log(error);
    }, () => {
      if (!active)
      {
        if (this.intervalOrder)
        {
          clearInterval(this.intervalOrder);
        }
      }
    });
  }


  getPhotoItems(): void
  {
    if (this.functions.getItemLocal('photos_items'))
    {
      this.photos = JSON.parse(this.functions.getItemLocal('photos_items'));
    }
  }

  getTotal(): void
  {
    /*FOR ORDER DETAILS*/
    const len = this.order.order_details.length;
    for (const [k, v] of Object.entries(this.order.order_details))
    {
      /*VALOR DO ITEM VEZES QUANTIDADE*/
      let addonPrice = 0;
      let addonText = '';
      const valuesCalc = {};
      let totalAverage = 0;
      let totalHigher = 0;
      /*VERIFICANDO ADICIONAIS*/
      if (v.addon)
      {
        addonPrice = 0;
        const addon = JSON.parse(v.addon);
        console.log(addon);
        for (const [l, m] of Object.entries(addon))
        {
          const val: any = m;
          addonText += `${ val.addon_qty + ' X '} ${val.addon_name}`;
          if (val.addon_price)
          {
            if (val.addon_calculate_type != null)
            {
              if (!valuesCalc[val.addon_calculate_type])
              {
                valuesCalc[val.addon_calculate_type] = {};
              }
              if (!Array.isArray(valuesCalc[val.addon_calculate_type][val.addon_calculate_type_cat_id]))
              {
                valuesCalc[val.addon_calculate_type][val.addon_calculate_type_cat_id] = [];
              }
              valuesCalc[val.addon_calculate_type][val.addon_calculate_type_cat_id].push(Number(val.addon_price));
            }
            else
            {
              addonPrice +=  (Number(val.addon_price) * Number(val.addon_qty));
              addonText += val.addon_price > 0 ? `(<b>${this.functions.prettyPrice(Number(val.addon_price) * Number(val.addon_qty))}</b>)` : '';
            }

          }
          addonText += ', ';
        }

        if (valuesCalc[`higher`] != null)
        {
          for (const [index, higher] of Object.entries(valuesCalc[`higher`]))
          {
            if (Array.isArray(higher))
            {
              totalHigher = higher.reduce((a, b) => {
                return  Math.max(a, b);
              }, -Infinity);
            }
          }
        }

        if (valuesCalc[`average`] != null)
        {
          for (const [index, average] of Object.entries(valuesCalc[`average`]))
          {
            if (Array.isArray(average))
            {
              const sum = average.reduce((partialSum, a) => partialSum + a, 0);
              totalAverage = Number(sum) / Number(average.length);
            }
          }
        }

        addonPrice += Number(totalAverage) + Number(totalHigher);
        addonText = addonText.slice(0, -2);
      }
      this.order.order_details[k].addon = addonText;

      let priceItem = 0;
      if (this.order.order_details[k].discounted_price)
      {
        priceItem = Number(this.order.order_details[k].discounted_price);
      }
      else if (this.order.order_details[k].normal_price)
      {
        priceItem = Number(this.order.order_details[k].discounted_price);
      }

      priceItem = (Number(priceItem) * Number(v.qty));
      this.order.order_details[k].discounted_price = Number(priceItem) + Number(addonPrice);
      this.order.order_details[k].normal_price = Number(priceItem);

    }

    this.total = this.subtotal;

    if (this.order.voucher_code)
    {
      this.total -= Number(this.order.voucher_amount);
    }

    if (this.order.delivery_charge)
    {
      this.total += Number(this.order.delivery_charge);
    }
  }

  closeModal(): void
  {
    this.modalService.dismissAll();
  }

  openReviewAdd(): void
  {
    this.functions.setItemLocal('reviewID', this.id);
    CartService.emitterAddCart.emit('openModalReviewAdd');
  }
}
