import {Component, Input, OnInit} from '@angular/core';

import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {

  @Input() location;

  map: google.maps.Map;
  loader: Loader;

  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  initMap(): void
  {
    /*const location = {lat: -23.349584892902197, lng: -47.84319000555725};*/

    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement,{
      center: this.location,
      zoom: 16,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false
    });

    const marker = new google.maps.Marker({
      position: this.location,
      map: this.map
    });

    /*console.log(this.map);*/
    /*console.log(document.getElementById('map'));*/
  }


}
