
<div class="login-page bg-light-01" >
  <div class="modal-header">
    <span class="h5 m-0">Cadastro</span>
    <a class="hc-nav-back ml-auto" (click)="activeModal.close()"><span><i class="feather-x h5"></i></span></a>
  </div>

  <form #formSignup='ngForm' (ngSubmit)="signup(formSignup)" class="" >
    <div class="modal-body">
      <p class="text-50">Faça o cadastro, só precisa do nome, email e telefone</p>
        <div class="form-row">
          <div class="form-group col-6">
            <label for="name" class="">Nome completo</label>
            <input #name type="text" placeholder="Seu nome" class="form-control" id="name" name="first_name" [(ngModel)]="userSignup.first_name" (change)="changeInput(name)" required>
          </div>
          <div class="form-group col-6">
            <label for="email" class="">Email</label>
            <input #email type="email" placeholder="seunome@email.com" class="form-control" id="email" name="email_address" [(ngModel)]="userSignup.email_address" (change)="changeInput(email)" required email>
          </div>
          <div class="form-group col-sm-4 col-6">
            <label for="contact_phone" class="">Celular</label>
            <input #cell type="phone" placeholder="(00) 00000-0000" mask="(00) 0000-0000||(00) 00000-0000" class="form-control" id="contact_phone" name="contact_phone" [(ngModel)]="userSignup.contact_phone" (change)="changeInput(cell)" required>
          </div>
          <div class="form-group col-sm-4 col-6">
            <label for="password" class="">Crie uma senha</label>
            <input #pass type="password" placeholder="Digite a senha" class="form-control" id="password" name="password" [(ngModel)]="userSignup.password" (change)="changeInput(pass)" required>
          </div>
          <div class="form-group col-sm-4 col-6">
            <label for="cpassword" class="">Confirme a senha</label>
            <input #cpass type="password" placeholder="Repita a senha" class="form-control" id="cpassword" name="cpassword" [(ngModel)]="userSignup.cpassword" (change)="changeInput(cpass)" required>
          </div>
        </div>
    </div>
    <div class="modal-footer rounded">
      <div class="row m-0 p-0 w-100">
        <button type="submit" class="btn btn-primary col-sm-6 rounded">Cadastrar</button>
      <div *ngIf="merchant.optionsMerchant.enabled_fblogin && merchant$ | async" class="py-2 col">
        <button class="btn btn-lg btn-facebook btn-block"><i class="feather-facebook"></i> Conectar com Facebook</button>
      </div>

        <a class="btn rounded col-sm-6" href="javascript:;" (click)="activeModal.close()">Já tenho conta, acessar agora...</a>


      </div>

    </div>
  </form>

</div>
