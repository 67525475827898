import {NgModule} from '@angular/core';


import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';


import {ProfileComponent} from './profile/profile.component';




const profileRoutes: Routes = [
  {path: 'profile/', component: ProfileComponent},
  {path: 'profile/:param', component: ProfileComponent},
];




@NgModule({
  imports: [RouterModule.forChild(profileRoutes)],
  exports: [RouterModule]

})
export class ProfileRoutingModule {}
