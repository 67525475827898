import { Injectable } from '@angular/core';
import {Pay} from '../class/pay';
import {CartDetails, Details} from '../class/cart';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ApiService} from './api.service';
import {FunctionsService} from './functions.service';
import {CartService} from './new/cart.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  private service$: Observable<ApiResponse>;

  protected addressError: boolean;
  protected paymentError: boolean;

  public payForm: Pay;

  public clickCancel: boolean;



  constructor(
      private service: ApiService,
      private functions: FunctionsService,
      private cartService: CartService
  )
  {
    this.clickCancel = false;
  }

  public changeAddressCart(address: any): Observable<ApiResponse>
  {
    return this.service.changeAddressCart(address);
  }


  validPayNow(): void
  {
    if (this.functions.getItemLocal('open') && this.functions.getItemLocal('open') === '0')
    {
      this.functions.showAlertError('Estabelecimento Fechado', 'Não é possivel realizar pedidos');
      return;
    }
    this.clickCancel = true;
    this.addressError = false;
    this.paymentError = false;

    const date = new Date();
    const dateNow = `${date.getFullYear()}-${date.getUTCMonth()}-${date.getDate()}`;
    const hourNow = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    this.payForm.delivery_date = dateNow;
    this.payForm.delivery_time = hourNow;
    this.payForm.delivery_asap = false;

    this.functions.log(this.cartService.details.transaction_type);
    // DELIVERY VALIDATION
    if (this.cartService.details.transaction_type === 'delivery')
    {
      if (this.cartService.cartDetails.street === '')
      {
        this.addressError = true;
        this.clickCancel = false;
        this.functions.showAlertError('Erro endereço', 'Selecione um endereço para entrega!');
        return;
      }
    }

    if (!this.payForm.payment_provider)
    {
      this.paymentError = true;
      this.clickCancel = false;
      this.functions.log(document.getElementById('__payment_form'));
      document.getElementById('__payment_form').classList.add('error');
      this.functions.showAlertError('Erro pagamento', 'Selecione uma forma de pagamento!');
      return;
    }

    if (!this.cartService.details.transaction_type)
    {
      this.paymentError = true;
      this.clickCancel = false;
      this.functions.showAlertError('Erro', 'Selecione se é para ENTREGA ou RETIRAR');
      return;
    }


    this.payForm.transaction_type = this.cartService.details.transaction_type;
    this.payForm.request_from = 'Web App';
/*
    this.functions.log(this.payForm);
    return ;
*/

    this.service$ = this.service.payNow(this.payForm);
    this.service$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
        this.service.loadCountOrder();
        this.cartService.cartCount = 0;
        if (this.payForm.payment_provider === 'pix') {
          this.functions.redirect('aguardando-pagamento', data.details.order_id);
        }
        else
        {
          this.functions.redirect('profile', 'order');
        }
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
      this.functions.showAlertError('Erro ao conectar ao servidor, tente novamente!');
      this.functions.log(error);
    }, () => {
      this.clickCancel = false;
    });
  }
}
