<div id="edit_profile"  *ngIf="userProfile$ | async; else loading">
  <h5 class="mb-4"><i class="feather-user mr-1"></i>Minha Conta</h5>
  <form #formProfile='ngForm'>
    <div class="row">
      <div class="form-group col-12 col-sm-6">
        <label>Nome</label>
        <input type="text" name="name" class="form-control" [(ngModel)]="name" required>
      </div>
      <div class="form-group  col-12 col-sm-6">
        <label>Telefone / Celular</label>
        <input type="text" class="form-control" name="contact_phone" mask="(00) 00000-0000"  [(ngModel)]="userProfile.contact_phone">
      </div>
      <div class="form-group col-12 col-sm-6">
        <label>E-mail</label>
        <input type="text" class="form-control" name="email" readonly  [(ngModel)]="userProfile.email_address" required email>
      </div>
    </div>
    <div class="row">
      <div class="text-cente col">
        <button type="submit" class="btn btn-primary"  (click)="saveProfile()">Atualizar dados</button>
      </div>
    </div>
  </form>
  <hr>
  <div class="additional">
    <div class="change_password my-3">
      <a href="javascript:;" (click)="openModal(modalPassword)"  class="p-3 border rounded bg-white btn d-flex align-items-center">Alterar Senha
        <i class="feather-arrow-right ml-auto"></i></a>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<!-- MODAL PASSWORD -->
<ng-template #modalPassword let-modalPass>

  <form #formPassword='ngForm' class="">
    <!-- MODAL HEADER -->
    <div class="modal-header bg-light border-bottom p-3">
      <h4 class="m-0">Alterar senha</h4>
      <button type="button" class="close" (click)="modalPass.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body p-3">
      <div class="row">
        <div class="form-group col-6">
          <label>Senha Atual</label>
          <input type="password" name="current_password" class="form-control" [(ngModel)]="passwordForm.current_password">
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="form-group col-6">
          <label>Nova Senha</label>
          <input type="password" class="form-control" name="password" [(ngModel)]="passwordForm.password">
        </div>
        <div class="form-group col-6">
          <label class="txt-nowrap">Confirmar nova Senha</label>
          <input type="password" class="form-control" name="cpassword" [(ngModel)]="passwordForm.cpassword">
        </div>
      </div>
    </div>
    <div class="modal-footer bg-white p-0 m-0">
      <div class="d-flex">
        
          <button type="button" class="btn btn-primary" (click)="changePassword()">Alterar Senha</button>
        
          <button type="button" class="btn" (click)="modalPass.dismiss('Cross click')"> <i class="feather-x-circle"></i> Fechar</button>
        
      </div>
      
    </div>
  </form>
  

</ng-template>



