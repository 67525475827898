<div class="rounded bg-white">
    <!-- ORDER LIST -->
    <div id="orders-list">
        <h5 class="mb-4"><i class="feather-file-text mr-1"></i>Meus pedidos</h5>

        <div *ngIf="!loadingOrder && orders.length == 0" class="col-12">
            <div class="text-center text-muted">Você não possui nenhum pedido em andamento</div>
            <div class="text-center my-4">
                <a href="javascript:;" class="btn btn-sm btn-primary" routerLink="/home">Fazer pedido</a>
            </div>
        </div>
        <h6 *ngIf="orders.length > 0" class="mb-2 pb-2 mt-3 border-bottom text-muted">PEDIDO EM ANDAMENTOS</h6>
        <div class="row" *ngIf="!loadingOrder && orders.length > 0">
            <div *ngFor="let order of orders" class="col-12">
                <div class="left mx-2 mr-4">
                    <p class="text-muted mb-2">PEDIDO
                        <span class="font-weight-bold m-0 my-1 mx-1">#{{order.order_id}}
                            <span class="badge-orders ml-3"
                                  [class.badge-novo]="order.status_code === 'INL'"
                                  [class.badge-preparo]="order.status_code === 'ACT'"
                                  [class.badge-pronto]="order.status_code === 'PRT'"
                                  [class.badge-entrega]="order.status_code === 'SAU'">
                                {{order.status | uppercase}}
                             </span>
                          </span>
                    </p>
                </div>
                <a *ngIf="order.status_code === 'PDT'" href="javascript:" [routerLink]="['/aguardando-pagamento', order.order_id]" class="btn btn-warning">Efetuar Pagamento</a>
                <!--[routerLink]="['/order', order.order_id]"-->
                <a href="javascript:;" (click)="openModalDetailsOrder(modalOrderDetails, order.order_id)" class="d-flex w-100 align-items-center py-1">
                    <div class="mx-2 mr-4">
                        <p class="small text-muted m-0">Data</p>
                        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.date_create}} <small class="text-muted">{{order.hour_create}}</small></h6>
                    </div>
                    <div>
                        <p class="small text-muted m-0">Total do pedido</p>
                        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.total}}</h6>
                    </div>
                    <div class="ml-auto">
                        <p class=" small text-muted m-0">Itens</p>
                        <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.count_cart}}</h6>
                    </div>
                    <div class="left ml-auto">
                        <p class=" small text-muted m-0">Ver +</p>
                        <h4 class="font-weight-bold m-0"><i class="feather-chevron-right rounded-circle bg-light"></i></h4>
                    </div>
                </a>
                <div class="d-flex progress-status border-bottom mt-2 mb-3 pb-2">
                    <div class="col text-center status-off"
                         [class.status-active]="order.status_code === 'INL' "
                         [class.status-off]="order.status_code !== 'INL'">
                        <p class="m-0 mb-1 text-center"><i class="feather-loader bg-novo p-1 rounded-circle"></i></p>
                        <span>AGUARDANDO CONFIRMAR</span>
                    </div>
                    <div class="col text-center"
                         [class.status-active]="order.status_code === 'ACT'"
                         [class.status-off]="order.status_code !== 'ACT'">
                        <p class="m-0 mb-1"><i class="feather-clock bg-preparo p-1 rounded-circle"></i></p>
                        <span>EM PREPARO</span>
                    </div>
                    <div class="col text-center "
                         [class.status-active]="order.status_code === 'PRT'"
                         [class.status-off]="order.status_code !== 'PRT'">
                        <p class="m-0 mb-1"><i class="feather-check-circle bg-pronto p-1 rounded-circle"></i></p>
                        <span>PEDIDO PRONTO</span>
                    </div>
                    <div class="col text-center"
                         [class.status-active]="order.status_code === 'SAU'"
                         [class.status-off]="order.status_code !== 'SAU'">
                        <p class="m-0 mb-1"><i class="feather-send bg-entrega p-1 rounded-circle"></i></p>
                        <span>SAIU P/ ENTREGA</span>
                    </div>
                </div>
            </div>
        </div>

        <app-loading *ngIf="loadingOrder"></app-loading>

    </div>
</div>

<h6 *ngIf="ordersDone.length > 0" class="mb-2 pb-2 mt-5 border-bottom text-muted">PEDIDO FINALIZADOS</h6>
<!-- Orders List -->
<div *ngIf="!loadingOrder && ordersDone.length > 0">
    <div class="mt-2 bg-white order-list" *ngFor="let order of ordersDone">
        <a href="javascript:;" (click)="openModalDetailsOrder(modalOrderDetails, order.order_id)"  class="d-flex w-100 align-items-center py-1 border-bottom " >
            <i class="p-2 rounded-circle mr-2"
               [class.feather-check]="order.status_code === 'FNL'"
               [class.bg-finalizado]="order.status_code === 'FNL'"
               [class.feather-x]="order.status_code === 'CLD'"
               [class.bg-cancelado]="order.status_code === 'CLD'">
            </i>
            <div class="left mx-2 mr-4">
                <p class="small text-muted m-0">Data</p>
                <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.date_create}}</h6>
                <p class="small text-muted m-0">{{order.hour_create}}</p>
                <p class="text-muted m-0 mt-2">Ped.: #{{order.order_id}}</p>
            </div>
            <div>
                <p class="small text-muted m-0 ">Total do pedido</p>
                <h6 class="font-weight-bold m-0 my-1 text-dark"> {{order.total}}</h6>
                <p class="badge-orders m-0 mt-2"
                   [class.badge-finalizado]="order.status_code === 'FNL'"
                   [class.badge-cancelado]="order.status_code === 'CLD'">
                    {{order.status | uppercase}}
                </p>
            </div>
            <div class="left ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
            </div>
        </a>
    </div>
</div>


<ng-template #modalOrderDetails let-modal>
    <app-order-details></app-order-details>
</ng-template>
