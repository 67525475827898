import {Component, Input, OnInit} from '@angular/core';
import {Review} from '../class/review';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ApiService} from '../services/api.service';

import {Merchant} from '../class/merchant';
import {Order} from '../class/order';
import {FunctionsService} from '../services/functions.service';
import {CartService} from '../services/cart.service';
import {ModalService} from '../services/new/modal.service';
import {ReviewService} from '../services/new/review.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReviewAddComponent} from '../review-add/review-add.component';
import {OrderDetailsService} from '../services/new/order-details.service';
import {OrderDetailsComponent} from '../order-details/order-details.component';


@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  @Input('modal') modal;
  review: Review[];
  orderReview: Order[];
  service$: Observable<ApiResponse>;
  merchant: Merchant;

  orderActive: boolean;
  login: boolean;

  constructor(
    private service: ApiService,
    private functions: FunctionsService,
    private reviewService: ReviewService,
    private orderDetailsService: OrderDetailsService,
    private ngbModalService: NgbModal,
    public activeModal: NgbActiveModal)
  {  }

  ngOnInit(): void {
    this.login = this.functions.isLogin();
    this.orderActive = false;
    this.merchant = this.functions.getItemLocal('merchantInfo') ? JSON.parse(this.functions.getItemLocal('merchantInfo')) : '';
    if (this.login)
    {
      this.getOrderReview();
    }
    this.getReview();
  }


  getReview(): void
  {
    this.service$ = this.service.loadReviews();
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.review = data.details.data;
      }
      else
      {
        this.functions.log(data.msg);
      }
    });
  }

  getOrderReview(): void
  {
    this.orderReview = [];
    this.service$ = this.service.get('getOrders');
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        const orders: Order[] = data.details.data;
        for (const v of orders)
        {
          if (v.add_review)
          {
            this.orderActive = true;
            this.orderReview.push(v);
          }
        }
        this.functions.setItemLocal('orderReview', JSON.stringify(this.orderReview));
      }

    });
  }


  openModalReviewAdd(id): void
  {
    this.reviewService.reviewId = id;
    this.ngbModalService.open(ReviewAddComponent);
  }

  seeOrder(id): void
  {
    this.orderDetailsService.orderId = id;
    this.ngbModalService.open(OrderDetailsComponent);
  }
}
