<div id="my-address-list" *ngIf="loadAddress$ | async; else loading">
  <h5 class="mb-4"><i class="feather-map-pin mr-1"></i>Meus Endereços</h5>
  <div>
    <div class="mt-3 py-3">
      <!--STEP 1-->
      <h6 class="d-flex">
        <div>Lista de endereços</div>
        <small class="ml-auto">
          <button class="btn btn-sm btn-outline-secondary small" (click)="openModalAddress()">
            <i class="feather-plus font-weight-bold mr-1"></i>ADICIONAR NOVO
          </button>
        </small>
      </h6>
      <div class="osahan-cart-item-profile rounded" *ngIf="addressList ; else noAddress">
        <div class="border-list-address p-3 w-100 rounded mb-2 d-flex" *ngFor="let address of addressList ; let i = index">
          <p class="m-0">{{address.street}}, {{address.number}} <br>
            <small>{{address.complemento ? address.complemento + ' - ' : ''}}  {{address.bairro}} - {{address.city}} - {{address.state}}</small><br>
            <!--- EXIBIR somente se o endereço for o padrão -->
            <small class="text-primary" *ngIf="address.as_default === '2'">ENDEREÇO PADRÃO</small>
            <!--- FIM DE EXIBIR somente se o endereço for o padrão -->
          </p>
          <div class="small ml-auto">
            <button class="btn btn-outline-info btn-sm small w-100 mb-2 p-1"  (click)="saveAddressDefault(address.id)">Tornar Padrão</button> <br>
            <button class="btn btn-outline-secondary btn-sm small w-100 mb-2 p-1"  (click)="optionsAddress(address.id)">EDITAR</button> <br>
            <button class="btn btn-outline-danger btn-sm small w-100 p-1" (click)="deleteAddress(address.id,  address.address)"> EXCLUIR</button>
          </div>
        </div>
      </div>

      <ng-template #noAddress>
        <div class="w-100 text-center">
          <p>Você ainda não possui nenhum endereço cadastrado</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<!---- MODAL EDIT ADDRESS -->

<ng-template #modalAddressEdit let-modal>
  <div class="modal-header"> <span class="h5"> Atualizar endereço</span>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

    <div id="map"></div>

    <form class="p-3" #formAddress='ngForm'>

      <div class="form-row">
        <div class="col-12 mb-3">
          <h6 class="m-0">{{addressEdit.street}}, {{addressEdit.number}}</h6>
          <small>{{addressEdit.bairro}} - {{addressEdit.city}} - {{addressEdit.state}}
          </small>
        </div>
        <div class="col-4 form-group">
          <label class="form-label">Número</label>
          <input type="text" class="form-control" name="number" [(ngModel)]="addressEdit.number">
        </div>
        <div class="col-7 form-group">
          <label class="form-label">Complemento</label>
          <input placeholder="Andar / Escritório / Apto / Depto" type="text" name="complemento" class="form-control" [(ngModel)]="addressEdit.complemento">
        </div>

        <div class="mb-0 col-8 col-md-6 form-group">
          <label class="form-label">Tipo de endereço:</label>
          <div ngbRadioGroup name="locationName" class="btn-group btn-group-toggle w-100" (change)="changeLocation($event)" [(ngModel)]="addressEdit.location_name">
            <label ngbButtonLabel for="casa" class="btn btn-outline-secondary">
              <input ngbButton type="radio" id="casa" [value]="'CASA'"><i class="feather-home"></i> Casa
            </label>
            <label ngbButtonLabel for="trabalho" class="btn btn-outline-secondary">
              <input ngbButton type="radio" id="trabalho" [value]="'TRABALHO'"><i class="feather-briefcase"></i> Trabalho
            </label>
          </div>
        </div>
        <div class="form-group col-4 mb-0">
          <label class="form-label"></label>
          <div class="custom-control custom-checkbox ml-3 mb-2">
            <input
                    #as_def
                    type="checkbox"
                    name="as_default"
                    id="as_default"
                    class="custom-control-input"
                    [checked]="addressEdit.as_default === '2'"
                    (click)="checkDefault(as_def)"
            >
            <label class="custom-control-label d-block pt-1" for="as_default">Endereço Padrão?</label>
          </div>
        </div>
        
      </div>

      <div class="col-12 text-center mt-4">
        <button type="button" class="btn btn-outline-danger float-right" (click)="deleteAddress(addressEdit.id, addressEdit.address)"> <i class="feather-x"></i>Excluir</button>
        <button type="button" class="btn btn-primary" (click)="saveAddress()" >
          <div *ngIf="btnLoadinSave" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Salvar Modificações
        </button>
      </div>
    </form>

</ng-template>

<!--<app-footer-bar></app-footer-bar>-->