


<footer class="section-footer border-top bg-dark">
    <div class="container">
        <section class="footer-top padding-y py-5">
            <div class="row pt-3">
                <aside class="col-md-4 footer-about">
                    <article class="d-flex pb-3 mb-md-0 mb-4">
                        <div><img alt="{{merchant.merchantName}}" src="{{merchant.optionsMerchant.logo_app}}" class="logo-footer mr-3"></div>
                        <div>
                            <h6 class="title text-white">Sobre o {{merchant.merchantName}}</h6>
                            <p class="text-muted">{{merchant.information}}</p>
                            <!--- CRIAR FUNÇÃO PARA HABILITAR SOMENTE SE HOUVER REDE SOCIAL ATIVA
                            <p class="h6 text-white mt-2"> Redes sociais</p>
                                --> 
                            <div class="d-flex align-items-center">
                                <a *ngIf="merchant.optionsMerchant.facebook_link" class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Facebook" target="_blank" href="{{merchant.optionsMerchant.facebook_link}}"><i class="feather-facebook"></i></a>
                                <a *ngIf="merchant.optionsMerchant.instagram_link" class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Instagram" target="_blank" href="{{merchant.optionsMerchant.instagram_link}}"><i class="feather-instagram"></i></a>
                                <a *ngIf="merchant.optionsMerchant.youtube_link" class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Youtube" target="_blank" href="{{merchant.optionsMerchant.youtube_link}}"><i class="feather-youtube"></i></a>
                                <a *ngIf="merchant.optionsMerchant.twitter_link" class="btn btn-icon btn-outline-light mr-1 btn-sm" title="Twitter" target="_blank" href="{{merchant.optionsMerchant.twitter_link}}"><i class="feather-twitter"></i></a>
                            </div>
                        </div>
                    </article>
                </aside>
                <aside class="col-sm-6 col-md-3 text-white">
                    <h6 class="title">
                        <i class="feather-clock h5 text-primary mr-2"></i>
                        <span>Horário de {{merchant.isHoursServiceDifferent ? 'Entrega' : 'Funcionamento'}}</span>
                    </h6>
                    <ul class="list-unstyled hov_footer">
                        <li class="text-muted" *ngFor="let open of merchant.hours_delivery_list">
                            {{open.day}}
                            <span *ngIf="open.hours">{{open.hours}}</span>
                            <span class="badge badge-danger" *ngIf="!open.hours">FECHADO</span>
                        </li>
                    </ul>
                </aside>

                <aside class="col-sm-6 col-md-3 text-white" *ngIf="merchant.isHoursServiceDifferent">
                    <h6 class="title">
                        <i class="feather-clock h5 text-primary mr-2"></i>
                        <span>Horário de Retirada</span>
                    </h6>
                    <ul class="list-unstyled hov_footer">
                        <li class="text-muted" 
                            *ngFor="let open of merchant.hours_pickup_list"> {{open.day}}
                            <span *ngIf="open.hours">{{open.hours}}</span>
                            <span class="badge badge-danger" *ngIf="!open.hours">FECHADO</span>
                        </li>
                    </ul>
                </aside>

                <aside class="col-sm-6 col-md-3 text-white" >
                    <!--<div class="mb-4" *ngIf="isLogin">
                        <h6 class="title">Conta</h6>
                        <ul class="list-unstyled hov_footer" *ngIf="pagesGeneric$ | async">
                            <li>
                                <a href="javascript:;" class="text-muted" (click)="functions.refresh('profile', 'order')">Meus Pedidos</a>
                            </li>
                            <li>
                                <a href="javascript:;" class="text-muted" (click)="functions.refresh('profile', 'account')">Minha Conta</a>
                            </li>
                            <li>
                                <a href="javascript:;" class="text-muted" (click)="functions.refresh('profile', 'address')">Meus Endereços</a>
                            </li>
                        </ul>
                    </div>-->
                    <!--<div *ngIf="showPageGeneric">
                        <h6 class="title">Informações</h6>
                        <ul class="list-unstyled hov_footer" *ngIf="pagesGeneric$ | async">
                            <li *ngFor="let page of pagesGeneric"><a href="javascript:;" class="text-muted" (click)="functions.redirectPageCustom(page.identify)">{{page.title}}</a></li>
                        </ul>
                        <ul class="list-unstyled hov_footer" *ngIf="isLogin && showPage && pages$ | async">
                            <li *ngFor="let page of pages"> <a href="javascript:;" class="text-muted" (click)="functions.redirectPageCustom(page.page_id)"> {{page.title}}</a></li>
                        </ul>
                    </div>-->
                </aside>
            </div>
            <hr class="border-bottom">
            <div class="row">
                <!--<aside class="col text-center ">
                    <h6 class="title text-muted mx-3">Opções de pagamento</h6>
                    <div class="d-flex text-white align-items-center justify-content-center">
                        <div class="mx-2 d-flex align-items-center" *ngFor="let payment of merchant.paymentList | keyvalue ">
                            <i class="feather-chevrons-right icon-color-02"></i>
                            {{payment.value}}
                            &lt;!&ndash;EXIBIÇÃO DAS BANDEIRAS DE CARTÃO&ndash;&gt;
                            <span class="d-flex align-items-center ml-2" *ngIf="payment.key === 'pyr'">
                                <div class="p-1 rounded bg-white m-1" *ngFor="let card of cardList">
                                    <img src="{{card.payment_logo}}" alt="{{card.payment_name}}" class="img-fluid" width="25">
                                </div>
                            </span>
                            &lt;!&ndash;FIM DAS BANDEIRAS&ndash;&gt;
                        </div>
                    </div>
                </aside>-->

            </div>
            <!-- row.// -->
        </section>

    </div>
    <!-- //container -->
    <section class="footer-copyright border-top py-3 bg-light">
        <div class="container d-flex align-items-center">
            <p class="mb-0"> ©  Click Mix Delivery - V 1.1</p>
            <p class="text-muted mb-0 ml-auto d-flex align-items-center">
                <a *ngIf="merchant?.googleplay_app && merchant?.googleplay_app === '2'" [href]="merchant?.googleplay_link" class="d-block"><img alt="#" src="assets/img/appstore.png" height="40"></a>
                <a *ngIf="merchant?.applestore_app && merchant?.applestore_app === '2'" [href]="merchant?.applestore_link" class="d-block ml-3"><img alt="#" src="assets/img/playmarket.png" height="40"></a>
            </p>
        </div>
    </section>
</footer>
