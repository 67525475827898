import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';

import {Observable} from 'rxjs';
import {ApiResponse} from './class/apiResponse';
import {ApiService} from './services/api.service';
import {FunctionsService} from './services/functions.service';
import {Merchant} from './class/merchant';
import {ColorsDefault} from './class/colors';
import {HideService} from './services/hide.service';
import {AddressService} from './services/address.service';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Delicious Burguer';

  eventHide$: Observable<boolean>;
  color: ColorsDefault;
  colorDefault: ColorsDefault;
  colorApp: ColorsDefault;

  service$: Observable<ApiResponse>;
  merchant: Merchant;

  action: string;
  ready: boolean;

  hide: boolean;
  constructor(
    private router: Router,
    public service: ApiService,
    private functions: FunctionsService,
    private addressService: AddressService,
    private metaService: Meta,
    private readonly titleService: Title,
    )
  {
    this.hide = false;
    this.eventHide$ = new Observable<boolean>();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void
  {
    this.eventHide$ = HideService.event;
    this.eventHide$.subscribe(data => {
      this.hide = data;
    });
    this.loadMerchantInfo();
    this.verifyLogin();
    /*console.log('Visit: ' + this.functions.isVisit());
    console.log('Login: ' + this.functions.isLogin());*/
    if (!this.functions.getItemLocal('transaction_type'))
    {
      this.functions.setItemLocal('transaction_type', 'delivery');
    }

    this.addressService.deliveryAddress = this.functions.getItemLocal('deliveryAddress') ? JSON.parse(this.functions.getItemLocal('deliveryAddress')) : false;
    this.addressService.distanceInfo = this.functions.getItemLocal('distanceInfo') ? JSON.parse(this.functions.getItemLocal('distanceInfo')) : false;
    this.addressService.deliveryCharge = this.functions.getItemLocal('deliveryCharge');
  }


  loadMerchantInfo(): void
  {
    this.service$ = this.service.getMerchantInfo();
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.merchant = data.details.data;
        if (this.merchant.optionsMerchant.custom_color === '1')
        {
          this.color = this.colorApp;
        }
        else
        {
          this.color = this.colorDefault;
        }
        // Adicionar informações SEO
        this.titleService.setTitle(`${this.merchant.merchantName} - Click Mix Delivery`);
        this.metaService.addTags([
          {name: 'title', content: `${this.merchant.merchantName} - ${this.merchant.seo?.title}`},
          {name: 'description', content: this.merchant.seo?.description ? this.merchant.seo.description : this.merchant.information},
          {name: 'keywords', content: this.merchant.seo?.keywords},
          {name: 'type', content: this.merchant.cuisine},
          {name: 'image', content: this.merchant.backgroundImage},
          {name: 'image:secure_ur', content: this.merchant.logo1},
          {name: 'image:alt', content: `Logo da ${this.merchant.merchantName}`},
          {name: 'og:title', content: `${this.merchant.merchantName} - ${this.merchant.seo?.title}`},
          {name: 'og:keywords', content: this.merchant.seo?.keywords},
          {name: 'og:image', content: this.merchant.logo1},
          {name: 'og:image:secure_ur', content: this.merchant.logo1},
          {name: 'og:image:alt', content: `Logo da ${this.merchant.merchantName}`},
          {name: 'og:type', content: this.merchant.cuisine},
          {name: 'og:description', content: this.merchant.seo?.description ? this.merchant.seo.description : this.merchant.information},
          {charset: 'UTF-8'}
        ]);
      }
      else if (data.code === 11)
      {
        this.functions.showAlertError('Estabelecimento Expirado', 'Entre em contato com um administrador');
      }
      else
      {
        console.log(data);
        let errorInit = 1;
        const errorInitLocal = this.functions.getItemLocal('errorInit');
        if (errorInitLocal)
        {
          errorInit = errorInit + Number(errorInitLocal);
        }
        this.functions.setItemLocal('erroInit', errorInitLocal.toString());
        this.functions.showAlertError('Houve um erro no servidor!', 'O app será reinicializado');
        document.location.reload();
      }
    }, error => {
      console.log(error);
      let errorInit = 1;
      const errorInitLocal = this.functions.getItemLocal('errorInit');
      if (errorInitLocal)
      {
        errorInit = errorInit + Number(errorInitLocal);
      }
      this.functions.setItemLocal('erroInit', errorInit.toString());
      this.functions.showAlertError('Houve um erro com a conexão do servidor!', 'O app será reinicializado');
      document.location.reload();
    }, () => {
      /*ARMAZENANDO INFO DO MERCHANT*/
      this.service$ = this.service.getServicesList();
      this.service$.subscribe(data => {
        if (data.code === 1)
        {
          this.merchant.services = data.details.data;
        }

      }, error => {

      }, () => {
        this.functions.setItemLocal('merchantInfo', JSON.stringify(this.merchant));
        this.service.loadDefaultColor();
      });

    });
  }

  verifyLogin(): void
  {
    if (!this.functions.isLogin() && !this.functions.getItemLocal('token'))
    {
      const token = 'visit_' + this.functions.getUUID('visit');
      this.functions.setItemLocal('token', token);
      this.service.changeToken(token);
    }
  }


}
