<div class="mb-10">

  <div class="modal-header bg-primary d-block p-3 d-flex ">
    <h4 class="m-0 text-white ">Recuperar Senha</h4>
    <a class=" text-white ml-auto" (click)="modal.dismiss('Cross click')"><i class="feather-x h4"></i></a>
  </div>

  <div class="mt-6 p-3">
    <p>Um link será enviado no seu e-mail para recuperar a senha</p>
    <form #formForgot='ngForm' (keyup.enter)="$event.preventDefault()">
      <div class="form-group">
        <label>E-mail</label>
        <input #inpEmail type="text" class="form-control" name="email" placeholder="email@email.com" [(ngModel)]="email" required email>
      </div>
      <button type="button" class="btn btn-primary btn-block" (click)="forgotPass(inpEmail)">Enviar e-mail</button>
    </form>

  </div>

</div>
