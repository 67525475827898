import {AfterContentChecked, AfterContentInit, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../services/api.service';
import {ApiResponse} from '../class/apiResponse';
import {Observable} from 'rxjs';
import {Address, AddressCompone} from '../class/address';
import {UserProfile} from '../class/user';
import {ActivatedRoute, Router} from '@angular/router';

import {Terms} from '../class/maps';
import {FunctionsService} from '../services/functions.service';
import {Merchant} from '../class/merchant';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddressService} from '../services/address.service';
import {CartService} from '../services/new/cart.service';

import {LoginComponent} from '../login/login.component';
import {MapsService} from '../services/maps.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, AfterContentInit {

  // VARIAVEL RESPONSAVEL PELO TEMPO DA REQUISIÇÃO COM GOOGLE MAPS
  timeSearchAddress = 700;


  @ViewChild('map', { static: false }) private mapDiv;
  /*@ViewChild('qryInput') qryInput: any;*/

  step: number;
  step3: boolean;

  validate: boolean;

  confirmLoading: boolean;

  userProfile: UserProfile;
  merchantInfo: Merchant;

  apiResponse: ApiResponse;
  apiResponse$: Observable<ApiResponse>;
  apiResponse2$: Observable<ApiResponse>;

  addressComp: AddressCompone[];
  saveAddress: Address;
  addressList: Address[];
  defaultAddress: string;
  terms: Terms[];
  addressSearch: string;
  localAddressList: any;

  streetNumber: string;
  locationName: string;

  divMap: HTMLElement;
  map: google.maps.Map;
  autoCompleteService: google.maps.places.AutocompleteService;
  geoCoder: google.maps.Geocoder;
  marker: google.maps.Marker;

  visit: boolean;
  paramUrl: string;
  routeParam: string;
  routeParam2: string;
  routeEnd: string;

  wait: any;

  showInfoMerchant: boolean;
  loadingChangeAddress: boolean;

  searchAddress: boolean;

  changeLayout: EventEmitter<any>;
  changeLayout$: EventEmitter<any>;

  verifyBrasilia: any;

  qryInput: string;
  qryInterval: any;

  msgErrorMap: any;

  loadingDraged: boolean;

  constructor(
    private service: ApiService,
    private router: Router,
    public functions: FunctionsService,
    private routeActive: ActivatedRoute,
    private modalService: NgbModal,
    private addressService: AddressService,
    private cartService: CartService,
    private mapService: MapsService)
  {
    this.qryInput = '';
    this.qryInterval = null;
    this.step = 1;
    this.step3 = true;
    this.validate = false;
    this.showInfoMerchant = false;
    this.searchAddress = false;
    this.changeLayout = new EventEmitter<any>();
    this.saveAddress = new Address();
    this.loadingDraged = false;
  }

  ngOnInit(): void {
    /*this.functions.log(this.addressService.deliveryAddress);*/
    this.functions.goToTop();
    this.changeLayout$ = this.changeLayout;
    this.changeLayout$.subscribe(data => {
      if (data)
      {
        this.getGeoCodeAddress(data);
      }
    });
    this.showInfoMerchant = AddressService.showInfoMerchant;
    if (this.functions.getItemLocal('AddressTmp'))
    {
      this.localAddressList = JSON.parse(this.functions.getItemLocal('AddressTmp'));
    }
    this.saveAddress = new Address();
    this.paramUrl = this.functions.getItemLocal('step') ? this.functions.getItemLocal('step') : '';
    if (this.functions.isLogin())
    {
      this.userProfile = JSON.parse(this.functions.getItemLocal('userProfile'));
    }
    this.merchantInfo = JSON.parse(this.functions.getItemLocal('merchantInfo'));
    this.getAddress();

  }

  ngAfterContentInit(): void
  {
    let merchantInfo: any = this.functions.getItemLocal('merchantInfo');
    merchantInfo = JSON.parse(merchantInfo);

    this.mapService.loadMap(merchantInfo.optionsMerchant.googlemaps_key, () => { this.initMapService(); } );

    if (!this.mapService.isActiveMap)
    {
      this.msgErrorMap = 'Sem API de Maps';
    }
  }


  openLogin(): void
  {
    this.modalService.open(LoginComponent);
  }


  initMap(): void
  {
    this.divMap = document.getElementById('map');

    this.map = new google.maps.Map(this.divMap, {
      zoom: 17,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false
    });
  }


  initMapService(): void
  {
    this.autoCompleteService = new google.maps.places.AutocompleteService();

    this.geoCoder = new google.maps.Geocoder();
    this.marker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
    });

    /*EVENT DRAGEND MARKER*/
    google.maps.event.addListener(this.marker, 'dragend', (ev) => {
      this.geoCoder.geocode({location: new google.maps.LatLng(ev.latLng.lat(), ev.latLng.lng())}, (r, s) => {
        if (s === 'OK')
        {
          this.addressSearch = r[0].formatted_address;
          this.defineAddress(r[0]);
          /*this.saveAddress.latitude = r[0].geometry.location.lat().toString();
          this.saveAddress.longitude = r[0].geometry.location.lng().toString();
          this.saveAddress.address = r[0].formatted_address;
          if (isNaN(Number(r[0].address_components[0].long_name)))
          {
            this.streetNumber = '';
            this.saveAddress.street = r[0].address_components[0].long_name;
            this.saveAddress.bairro = r[0].address_components[1].long_name;
            this.saveAddress.city = r[0].address_components[2].long_name;
            this.saveAddress.state = r[0].address_components[3].long_name;
            this.saveAddress.zipcode = r[0].address_components[5] ? r[0].address_components[5].long_name : '';


          }
          else
          {
            this.streetNumber = r[0].address_components[0].long_name;
            this.saveAddress.number = r[0].address_components[0].long_name;
            this.saveAddress.street = r[0].address_components[1].long_name;
            this.saveAddress.bairro = r[0].address_components[2].long_name;
            this.saveAddress.city = r[0].address_components[3].long_name;
            this.saveAddress.state = r[0].address_components[4].long_name;
            this.saveAddress.zipcode = r[0].address_components[6] ? r[0].address_components[6].long_name : '';
          }*/
        }
      });
    });
  }


  changeAddressTmp(index): void
  {
    this.loadingChangeAddress = true;
    /*this.functions.log(this.localAddressList[index]);*/
    this.apiResponse$ = this.service.checkDistanceMerchant(this.localAddressList[index]);
    this.apiResponse$.subscribe(data => {
      if (data.code === 1)
      {
        const deliveryCharge = data.details.delivery_fee;
        this.cartService.deliveryFee = Number(deliveryCharge) === 0 ? 'Taxa de Entrega Grátis' : deliveryCharge;
        this.cartService.deliveryEstimation = data.details.delivery_estimation;
        this.functions.setItemLocal('distanceInfo', JSON.stringify(data.details));
        this.functions.setItemLocal('deliveryCharge', deliveryCharge);
        this.functions.setItemLocal('deliveryEstimation', data.details.delivery_estimation);
        this.functions.setItemLocal('deliveryAddress', JSON.stringify(this.localAddressList[index]));
        this.apiResponse$ = this.service.changeAddressCart(this.localAddressList[index]);
        this.apiResponse$.subscribe(adCart => {
          if (adCart.code === 1)
          {
            this.addressService.changeAddress();
            this.modalService.dismissAll();
            this.loadingChangeAddress = false;
          }
        });

      }
    });

  }

  cleanAddressTmp(): void
  {
    this.functions.removeItemLocal('AddressTmp');
    delete this.localAddressList;
  }

  closeModal(): void
  {
    this.modalService.dismissAll();
  }

  getQuery(): void
  {
    if (this.qryInterval)
    {
      clearInterval(this.qryInterval);
    }

    const displaySuggestions = (predictions, status) => {
      document.getElementById('resultAddress').innerHTML = '';
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        const erro = document.createElement('li');
        erro.innerHTML = 'Endereço não encontrado';
        erro.classList.add('list-group-item');
        document.getElementById('resultAddress').appendChild(erro);
        return;
      }

      predictions.forEach((prediction, key ) => {
        if (key < 5)
        {
          const li = document.createElement('li');
          const liContent = `<a href="javascript:;" class="btn-link text-black">${prediction.description}</a>`;
          li.classList.add('list-group-item');
          li.innerHTML = liContent;
          document.getElementById('resultAddress').appendChild(li);
        }
      });
    };

    this.qryInterval = setTimeout(() => {
      if (this.qryInput)
      {
        this.step3 = false;
        this.autoCompleteService.getPlacePredictions({input: this.qryInput, componentRestrictions: {country: 'br'}}, displaySuggestions);
      }
      else
      {
        this.step3 = true;
        document.getElementById('resultAddress').innerHTML = '';
      }
    }, this.timeSearchAddress);

  }

  selectAddress(e): void
  {

    if (e.target.nodeName === 'A')
    {
      this.getGeoCodeAddress(e.target.text);
      /*this.autoCompleteService.getPlacePredictions({input: e.target.text}, (predictions, status) => {
        this.terms = predictions[0].terms;
        this.addressSearch = predictions[0].description;
        this.verifyBrasilia = this.addressSearch.split(',').includes(' Brasília - DF');
        console.log(e.target.text);
        console.log(this.addressSearch);
        this.getGeoCodeAddress(e.target.text);
      });*/
    }
  }

  getGeoCodeAddress(addressFull): void
  {
    this.addressSearch = addressFull;
    this.verifyBrasilia = this.addressSearch.split(',').includes(' Brasília - DF');

    this.geoCoder.geocode({address: this.addressSearch}, (results, s) => {
      this.initMap();
      this.marker.setDraggable(true);
      this.searchAddress = false;
      if (results[0].types.indexOf('point_of_interest') > -1)
      {
        this.marker.setDraggable(false);
      }

      /*if (predict[0].types.indexOf('point_of_interest') > -1)
      {
        this.marker.setDraggable(false);
      }
      else
      {
        this.marker.setDraggable(true);
      }*/


      this.defineAddress(results[0]);

      this.searchAddress = false;
      this.map.setCenter(results[0].geometry.location);
      this.marker.setMap(null);
      this.marker.setPosition(results[0].geometry.location);
      this.marker.setMap(this.map);
      this.locationName = 'CASA';
      this.step = 2;
    });
  }

  // FUNÇÃO RESPONSÁVEL POR IDENTIFICAR O ENDEREÇO
  defineAddress(results): void
  {
    console.log(results);
    const addressComponent = results.address_components;
    const types = results?.types;

    this.saveAddress = new Address();

    if (this.verifyBrasilia)
    {
      this.saveAddress.address = this.addressSearch;
    }
    else
    {
      this.saveAddress.address = results.formatted_address;
    }

    this.saveAddress.latitude = results.geometry.location.lat().toString();
    this.saveAddress.longitude = results.geometry.location.lng().toString();

    let tmpStreet = this.addressSearch;
    for (const address of addressComponent)
    {
      if (address.types.includes('postal_code'))
      {
        this.saveAddress.zipcode = address.long_name;
      }
      else if (address.types.includes('street_number'))
      {
        this.saveAddress.number = address.long_name;
      }
      else if (address.types.includes('administrative_area_level_2'))
      {
        this.saveAddress.city = address.long_name;
      }
      else if (address.types.includes('administrative_area_level_1'))
      {
        this.saveAddress.state = address.short_name;
      }

      else if (address.types.includes('country'))
      {
        this.saveAddress.country_code = address.short_name;
      }

      else if (address.types.includes('sublocality_level_1'))
      {
        this.saveAddress.bairro = address.short_name;
      }

      else if (address.types.includes('administrative_area_level_3'))
      {
        if (!this.saveAddress.bairro.includes(address.short_name))
        {
          this.saveAddress.bairro += ' ' + address.short_name;
        }
      }

      else if (address.types.includes('administrative_area_level_4'))
      {
        if (!this.saveAddress.bairro.includes(address.short_name))
        {
          this.saveAddress.bairro += ' ' + address.short_name;
        }
      }

      else if (address.types.includes('administrative_area_level_5'))
      {
        if (!this.saveAddress.bairro.includes(address.short_name))
        {
          this.saveAddress.bairro += ' ' + address.short_name;
        }
      }

      else if (address.types.includes('administrative_area_level_6'))
      {
        if (!this.saveAddress.bairro.includes(address.short_name))
        {
          this.saveAddress.bairro += ' ' + address.short_name;
        }
      }

      else if (address.types.includes('administrative_area_level_7'))
      {
        if (!this.saveAddress.bairro.includes(address.short_name))
        {
          this.saveAddress.bairro += ' ' + address.short_name;
        }
      }

      else if (address.types.includes('route'))
      {
        this.saveAddress.street = address.long_name;
      }

      else if (address.types.includes('street_address'))
      {
        if (!this.saveAddress.street.includes(address.long_name))
        {
          this.saveAddress.street += ' ' + address.long_name;
        }
      }
    }

    if (this.saveAddress.street === '')
    {
      tmpStreet = tmpStreet.split('-')[0];
      this.saveAddress.street = tmpStreet.replace(/[^a-zA-Z\s]/g, '');
    }


    if (this.verifyBrasilia)
    {
      if (this.saveAddress.bairro.includes('Santa Maria'))
      {
        if (this.saveAddress.street.includes('Total Ville') && !this.saveAddress.street.includes('Santa Maria'))
        {
          this.saveAddress.street += ' Santa Maria';
        }
        else if (!this.saveAddress.street.includes('Total Ville') && !this.saveAddress.street.includes('Santa Maria'))
        {
          this.saveAddress.street += ' Total Ville Santa Maria';
        }
      }
    }

    console.log(this.saveAddress);
  }

  locationNameChange(e): void
  {
    this.locationName = e.target.value;
  }

  checkDefault(inp): void
  {
    if (inp.checked)
    {
      this.saveAddress.as_default = '2';
    }
    else
    {
      this.saveAddress.as_default = '1';
    }
  }

  registerAddress(form): void
  {
    this.validate = false;
    this.saveAddress.location_name = this.locationName;
    this.saveAddress.number = this.streetNumber;
    let deliveryCharge = '';
    if (!this.saveAddress.number)
    {
      this.validate = true;
      return;
    }

    if (this.verifyBrasilia && !this.saveAddress.complemento)
    {
      this.validate = true;
      return;
    }

    if (this.verifyBrasilia && !this.saveAddress.reference)
    {
      this.validate = true;
      return;
    }

    if (this.verifyBrasilia)
    {
      this.saveAddress.number = 'QD ' + this.saveAddress.number;
    }

    /*this.saveAddress.address = `${this.saveAddress.street}, ${this.saveAddress.number} - ${this.saveAddress.bairro}, `;
    this.saveAddress.address += `${this.saveAddress.city} - ${this.saveAddress.state} ${this.saveAddress.zipcode ? ', ' + this.saveAddress.zipcode : ''}`;*/
    this.confirmLoading = true;
    console.log(this.saveAddress);
    this.apiResponse$ = this.service.checkDistanceMerchant(this.saveAddress);
    this.apiResponse$.subscribe(data => {
      if (data.code === 1)
      {
        console.log('retorno do check distance');
        console.log(data.details);
        deliveryCharge = data.details.delivery_fee;
        this.cartService.deliveryFee = Number(deliveryCharge) === 0 ? 'Taxa de Entrega Grátis' : deliveryCharge;
        this.cartService.deliveryEstimation = data.details.delivery_estimation;
        const tmp = this.functions.getItemLocal('AddressTmp') ? JSON.parse(this.functions.getItemLocal('AddressTmp')) : [];
        let existAddressLocal = false;
        for (const ad of tmp)
        {
          if (ad.address === this.saveAddress.address)
          {
            existAddressLocal = true;
            break;
          }
        }

        if (!existAddressLocal)
        {
          tmp.push(this.saveAddress);
        }
        this.functions.setItemLocal('AddressTmp', JSON.stringify(tmp));
        this.functions.setItemLocal('deliveryAddress', JSON.stringify(this.saveAddress));
        this.functions.setItemLocal('distanceInfo', JSON.stringify(data.details));
        this.functions.setItemLocal('deliveryCharge', deliveryCharge);
        this.functions.setItemLocal('deliveryEstimation', data.details.delivery_estimation);
        this.apiResponse$ = this.service.changeAddressCart(this.saveAddress);
        this.apiResponse$.subscribe(d => {
          AddressService.showInfoMerchant = false;
          if (d.code === 1)
          {
            if (form.valid)
            {
              if (this.functions.isLogin())
              {
                this.apiResponse$ = this.service.saveAddress(this.saveAddress);
                this.apiResponse$.subscribe(da => {
                  if (da.code === 1)
                  {
                    this.functions.showAlertSuccess(da.msg);
                    this.setAddressDelivery(da.details);
                  }
                  else
                  {
                    this.functions.showAlertError(da.msg);
                  }
                }, error => {

                }, () => {
                 this.actionBeforeSave();
                });
              }
              else
              {
                this.actionBeforeSave();
              }

            }
          }
          else
          {
            console.log('error add address cart');
            this.confirmLoading = false;
            this.functions.showAlertError(d.msg);
          }
        });
      }
      else
      {
        this.confirmLoading = false;
        this.functions.showAlertError(data.msg);
        console.log('Check distance error');
        return;
      }
    }, error => {
      this.functions.showAlertErroServer();
    }, () => {

    });
  }

  actionBeforeSave(): void
  {
    this.modalService.dismissAll();
    this.addressService.changeAddress();
    this.functions.showAlertSuccess('Endereço trocado');
    if (this.cartService.cartCount > 0)
    {
      /*console.log('action before save execute');*/
      this.cartService.loadCart();
    }
  }


  changeAddressDelivery(id): void
  {
    /*this.functions.log(id);*/
    for (const [k, v] of Object.entries(this.addressList))
    {
      /*this.functions.log(v.address);*/

      if (v.address === id)
      {
        /*this.functions.log(v.address);*/

        this.loadingChangeAddress = true;
        this.apiResponse$ = this.service.checkDistanceMerchant(v);
        this.apiResponse$.subscribe(data => {
          if (data.code === 1)
          {
            const deliveryCharge = data.details.delivery_fee;
            this.cartService.deliveryFee = Number(deliveryCharge) === 0 ? 'Taxa de Entrega Grátis' : deliveryCharge;
            this.cartService.deliveryEstimation = data.details.delivery_estimation;
            this.functions.setItemLocal('distanceInfo', JSON.stringify(data.details));
            this.functions.setItemLocal('deliveryCharge', deliveryCharge);
            this.functions.setItemLocal('deliveryAddress', JSON.stringify(v));
            if (this.paramUrl === 'payment')
            {
              this.apiResponse$ = this.service.changeAddressCart(v);
              this.apiResponse$.subscribe(d => {
                if (d.code === 1)
                {
                  this.actionBeforeSave();
                }
                else
                {
                  this.functions.showAlertError(d.msg);
                }
              }, error => {
                this.functions.log(error);
                this.functions.showAlertErroServer();
              });
            }
            else
            {
              this.actionBeforeSave();
            }
          }
        }, error => {
          this.functions.log(error);
          this.functions.showAlertErroServer();
        }, () => {
          this.loadingChangeAddress = false;
        });
        break;
      }
    }
  }


  getAddress(): void
  {
    this.apiResponse$ = this.service.getAddressBookList();
    this.apiResponse$.subscribe(data => {
      this.apiResponse = data;
      this.addressList = this.apiResponse.details.data;
      if (this.functions.getItemLocal('deliveryAddress'))
      {
        this.defaultAddress = JSON.parse(this.functions.getItemLocal('deliveryAddress')).address;
      }
      else if (this.addressList)
      {
        for (const c of this.addressList)
        {
          if (c.as_default === '2')
          {
            /*this.functions.log(c);*/
            this.defaultAddress = c.address;
          }
        }
      }
    }, error => {},
      () => {});
  }

  setAddressDelivery(id): void
  {
    this.apiResponse$ = this.service.setAddressBook(id, this.userProfile.contact_phone);
    this.apiResponse$.subscribe(data => {this.functions.log(data); },
      error => {},
      () => {
        this.addressService.changeAddress();
      });
  }




  backStep1(): void
  {
    this.step = 1;
    this.step3 = true;
  }


}
