<header class="section-header" *ngIf="service.color$" id="__top">
    <section class="header-main shadow-sm bg-white fixed-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-1">
                    <a href="javascript:;" routerLink="/home" class="brand-wrap mb-0">
                        <img alt="{{merchant.merchantName}}" src="{{merchant.optionsMerchant?.logo_app}}">
                    </a>
                    <!-- brand-wrap.// -->
                </div>
                <div class="col-5 d-flex align-items-center sm-none">
                    <div class="dropdown mr-3">
                        <a class="text-dark dropdown-toggle d-flex align-items-center py-3" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div><i class="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size"></i></div>
                            <div class="header-address-delivery">
                                <p class="text-muted mb-0 small">Entregar em</p>
                                <span *ngIf="deliveryAddress?.street">
                                    {{deliveryAddress.street}}, {{deliveryAddress.number}}
                                </span>
                                <span *ngIf="!deliveryAddress?.street">
                                    Informe o seu endereço
                                </span>
                            </div>
                        </a>
                        <div class="dropdown-menu p-0 drop-loc" aria-labelledby="navbarDropdown">
                            <div class="osahan-country">
                                <div class="p-3 border-bottom" *ngIf="!cartService?.cartDetails?.street">
                                    <a href="javascript:;" class="text-decoration-none" (click)="openModalAddress()">
                                        <p class="font-weight-bold text-primary m-0"><i class="feather-navigation"></i> Adicionar endereço</p>
                                    </a>
                                </div>

                                <div class="p-3 border-bottom" *ngIf="cartService.cartDetails?.street">
                                    <a href="javascript:;" class="text-decoration-none" (click)="openModalAddress()">
                                        <p class="font-weight-bold text-primary m-0"><i class="feather-navigation"></i> Trocar endereço</p>
                                    </a>
                                </div>
                                <div class="filter" *ngIf="addressTmp">
                                    <h6 class="px-3 py-3 bg-light pb-1 m-0 border-bottom">Endereços pesquisados</h6>
                                    <div *ngIf="!load">
                                        <div class="custom-control  border-bottom px-0 custom-radio" *ngFor="let address of addressTmp">
                                            <input
                                                    type="radio"
                                                    id="_{{address.latitude}}{{address.longitude}}"
                                                    name="location"
                                                    class="custom-control-input"
                                                    [checked]="deliveryAddress.address === address.address"
                                                    (click)="changeAddress(address.address)"
                                            >
                                            <label class="custom-control-label py-3 w-100 px-3" for="_{{address.latitude}}{{address.longitude}}">{{address.street}}, {{address.number}} <br> <small>{{address.complemento ? address.complemento + ' - ' : ''}} {{address.city}} - {{address.state}}</small> </label>
                                        </div>
                                    </div>

                                    <ng-template [ngIf]="load">
                                        <app-loading></app-loading>
                                    </ng-template>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- col.// -->
                <div class="col-5">
                    <div class="d-flex align-items-center justify-content-end">
                        <!-- signin -->
                        <a *ngIf="!isLogin"  href="javascript:;" (click)="openModalLogin()" class="widget-header mr-4 text-dark sm-none">
                            <div class="icon d-flex align-items-center">
                                <i class="feather-user h6 mr-2 mb-0"></i> <span>Entrar</span>
                            </div>
                        </a>
                        <!-- my account -->
                        <div *ngIf="isLogin" class="dropdown mr-4 sm-none">
                            <a href="#" class="dropdown-toggle text-dark py-3 d-block" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Olá {{user.first_name}},
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="javascript:;" (click)="functions.refresh('profile', 'order')">Meus Pedidos</a>
                                <a class="dropdown-item" href="javascript:;" (click)="functions.refresh('profile', 'account')">Minha Conta</a>
                                <a class="dropdown-item" href="javascript:;" (click)="functions.refresh('profile', 'address')">Meus endereços</a>
                                <a class="dropdown-item" href="javascript:;" (click)="logout()">Sair</a>
                            </div>
                        </div>
                        <!-- signin -->
                        <a href="javascript:;" (click)="openModalCart()" class="widget-header mr-4 text-dark">
                            <div class="icon d-flex align-items-center my-2">
                                <i class="feather-shopping-bag h6 mr-2 mb-0"></i>
                                <span *ngIf="cartService.cartCount > 0" class="badge-item-header position-absolute ml-2 text-white"><span class="small">{{cartService.cartCount}}</span></span>
                                <span>cesta</span>
                            </div>
                        </a>
                    </div>
                    <!-- widgets-wrap.// -->
                </div>
                <!-- col.// -->
            </div>
            <!-- row.// -->
        </div>
        <!-- container.// -->
    </section>
    <!-- header-main .// -->
</header>


<div class="d-none header-mobile">
    <div>
        <div class="header-mobile-logo">
            <a href="javascript:;" routerLink="/home">
                <img alt="{{merchant.merchantName}}" class="" src="{{merchant.optionsMerchant.logo_app}}">
            </a>
        </div>
        <div class="header-mobile-address">
            <small>MEU LOCAL: </small>
            <p>
                <span *ngIf="deliveryAddress.street"> {{deliveryAddress.street}}, {{deliveryAddress.number}} - {{deliveryAddress.bairro}} - {{deliveryAddress.city}}</span>
                <span *ngIf="!deliveryAddress.street"> Informe o seu endereço </span>
            </p>
        </div>
        <div>
          <button class="btn btn-outline-secondary btn-sm small ml-auto px-1 py-0" *ngIf="!deliveryAddress.street" (click)="openModalAddress()">ADICIONAR ENDEREÇO</button>
          <button class="btn btn-outline-secondary btn-sm small ml-auto px-1 py-0" *ngIf="deliveryAddress.street"  (click)="openModalAddress()">TROCAR</button>
        </div>
    </div>
</div>


