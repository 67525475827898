import {Component, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../../class/order';
import {ApiResponse} from '../../class/apiResponse';
import {Observable} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FunctionsService} from '../../services/functions.service';
import {OrderDetailsComponent} from '../../order-details/order-details.component';

@Component({
  selector: 'app-order-profile',
  templateUrl: './order-profile.component.html',
  styleUrls: ['./order-profile.component.css']
})
export class OrderProfileComponent implements OnInit, OnDestroy {

  orders: Order[];
  ordersDone: Order[];
  apiResponse: ApiResponse;
  service$: Observable<ApiResponse>;
  service2$: Observable<ApiResponse>;

  loadingOrder: boolean;
  loadingOrderActive: boolean;


  orderInterval: any;
  orderActive: boolean;
  visit: boolean;

  route: string;

  constructor(
      private service: ApiService,
      private modalService: NgbModal,
      public functions: FunctionsService) { }

  ngOnInit(): void {
    this.loadingOrder = false;
    this.loadingOrderActive = false;
    this.loadOrders();
  }

  loadOrders(): void
  {
    let countOrder = 0;
    this.orders = [];
    this.ordersDone = [];
    this.service$ = this.service.get('getOrders');
    this.loadingOrder = true;
    this.service$.subscribe(data => {
      this.apiResponse = data;
      this.functions.log(data);
      if (this.apiResponse.details.data)
      {
        for (const [key, value] of Object.entries(this.apiResponse.details.data as Order))
        {
          if (value.status_code === 'FNL' || value.status_code === 'CLD')
          {
            this.ordersDone.push(value);
          }
          else
          {
            countOrder += 1;
            this.orders.push(value);
            this.orderActive = true;
          }
        }
        if (countOrder === 0)
        {
          this.functions.setItemLocal('orderActiveCount', '');
        }
        else
        {
          this.functions.setItemLocal('orderActiveCount', countOrder.toString());
        }
        this.functions.log(this.orders);
        this.functions.log(this.ordersDone);

      }
    }, error => {
      this.functions.showAlertError('Houve um erro com o servidor, tente novamente mais tarde');
      this.functions.log(error);
    }, () => {
      this.loadingOrder = false;
      if (this.orderActive)
      {
        this.orderInterval = setInterval(() => {
          this.loadOrdersActive();
        }, 20000);
      }
    });
  }

  loadOrdersActive(): void
  {
    /*console.log('Load order Active');
    console.log(this.orders);*/
    let countOrder = 0;
    this.service2$ = this.service.get('getOrders');
    this.loadingOrderActive = true;
    this.service2$.subscribe(data => {
      this.apiResponse = data;
      if (this.apiResponse.details.data)
      {
        for (const [key, value] of Object.entries(this.apiResponse.details.data as Order))
        {
          for (const [k, v] of Object.entries(this.orders))
          {
            if (v.order_id === value.order_id)
            {
              this.orders[k].status = value.status;
              this.orders[k].status_code = value.status_code;
              this.orders[k].add_review = value.add_review;
              if (value.status_code === 'FNL' || value.status_code === 'CLD')
              {
                this.ordersDone.push(this.orders[k]);
              }
              else
              {
                countOrder += 1;
              }
            }
          }
          this.orders = this.orders.filter((e) => {
            return e.status_code !== 'FNL';
          });
        }
        if (countOrder === 0)
        {
          this.orderActive = false;
          this.functions.setItemLocal('orderActiveCount', '');
          this.orders = [];
        }
        else
        {
          this.orderActive = true;
          this.functions.setItemLocal('orderActiveCount', countOrder.toString());
        }
      }
    }, error => {
      this.functions.log(error);
    }, () => {
      this.loadingOrderActive = false;
      if (!this.orderActive)
      {
        if (this.orderInterval) {
          clearInterval(this.orderInterval);
        }
      }
    });
  }

  orderDetailsOpen(): void
  {
    // const modalRef = this.modalService.open(ModalComponent);
    const modalRef = this.modalService.open(OrderDetailsComponent);
    modalRef.componentInstance.title = 'About';
  }

  openModalDetailsOrder(modal, id): void
  {
    this.functions.setItemLocal('orderID', id);

    this.modalService.open(modal);
  }


  ngOnDestroy(): void
  {
    if (this.orderInterval) {
      clearInterval(this.orderInterval);
    }
  }

}
