<!-- Menu Footer BAR-->
<div class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-none">
  <div class="row">
    <div class="col">
      <a href="javascript:;" routerLink="/home" class="text-dark small font-weight-bold text-decoration-none">
        <p class="h4 m-0"><i class="feather-home text-dark"></i></p>
        Home
      </a>
    </div>
    <div class="col">
      <a href="javascript:;" class="text-dark small font-weight-bold text-decoration-none" (click)="openReview()">
        <p class="h4 m-0"><i class="feather-star"></i>
          <span *ngIf="reviewCount" class="badge-item-review position-absolute"><span>{{reviewCount}}</span></span>
        </p>
        Avaliações
      </a>
    </div>

    <div class="col bg-white rounded-circle mt-n4 px-3 py-2">
      <div class="bg-primary rounded-circle mt-n0 shadow">
        <div *ngIf="cartService.cartCount > 0" class="badge-item position-absolute text-white"><span>{{cartService.cartCount}}</span></div>
        <a class="text-white small font-weight-bold text-decoration-none" href="javascript:;" (click)="openCart()">
          <i class="feather-shopping-cart"></i>
        </a>
      </div>
    </div>
    <div class="col">
      <a href="javascript:;" class="text-dark small font-weight-bold text-decoration-none" (click)="openProfile('order')">
        <p class="h4 m-0"><i class="feather-file-text"></i>
          <span *ngIf="orderActiveCount" class="badge-item-order position-absolute"><span>{{orderActiveCount}}</span></span>
        </p>
        Pedidos
      </a>
    </div>
    <div class="col">
      <a href="javascript:;" class="text-dark small font-weight-bold text-decoration-none" (click)="openProfile('account')">
        <p class="h4 m-0"><i class="feather-user"></i></p>
        Perfil
      </a>
    </div>
  </div>
</div>
