import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription, Observable} from 'rxjs';
import {AguardandoPagamentoService} from './aguardando-pagamento.service';
import {ActivatedRoute} from '@angular/router';
import {FunctionsService} from '../services/functions.service';
import {Merchant} from '../class/merchant';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-aguardando-pagamento',
  templateUrl: './aguardando-pagamento.component.html',
  styleUrls: ['./aguardando-pagamento.component.css']
})
export class AguardandoPagamentoComponent implements OnInit, OnDestroy {

  orderId: any;
  order: any;
  order$: Observable<any>;
  merchantInfo: Merchant;
  verifyInterval: any;
  verifyPixInterval: any;
  contador: any;
  pixInfo: any;
  isCancelPix: boolean;
  isPixPay: boolean;
  isShowInfoPix: boolean;

  subscriptionVerifyPix: Subscription;
  subscriptionTimer: Subscription;

  constructor(
      private service: AguardandoPagamentoService,
      private route: ActivatedRoute,
      private functions: FunctionsService,
      private modalService: NgbModal
  )
  {
    this.order$ = new Observable<any>();
    this.isCancelPix = false;
    this.isPixPay = false;
    this.isShowInfoPix = false;
  }

  ngOnInit(): void
  {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchantInfo = JSON.parse(this.functions.getItemLocal('merchantInfo'));
    }
    this.orderId = this.route.snapshot.params[`orderId`];
    this.getOrderInfo();
    this.createPix();

    const source = interval(5000);
    this.subscriptionVerifyPix = source.subscribe(() => {
      console.log('verificando pix');
      this.getInfoPix();
    });

    /*this.verifyInterval = setInterval(() => {
      console.log('verificando pix');
      this.getInfoPix();
    }, 5000);*/
  }

  ngOnDestroy(): void
  {
    this.clearAllIntervalActive();
  }

  copyText(text, msg): void
  {
    navigator.clipboard.writeText(text);
    this.functions.showAlertSuccess(msg);
  }

  copyQrCode(): void
  {
    this.copyText(this.pixInfo.pix_key, 'Código copiado com sucesso!');
  }

  getOrderInfo(): void
  {
    this.order$ = this.service.getOrderInfo(this.orderId);
    this.order$.subscribe(data => {
      console.log(data);
      if (data.code === 1)
      {
        this.order = data.details;
      }
    });
  }

  createPix(): void
  {
    this.order$ = this.service.createPix(this.merchantInfo.merchantId, this.orderId);
    this.order$.subscribe(infoPix => {
      if (infoPix.code === 1)
      {
        this.isShowInfoPix = true;
        this.pixInfo = infoPix.details;
        this.createIntervalTimePix(infoPix);
      }
      else if (infoPix.code === 7)
      {
        this.cancelPix();
      }
      else if (infoPix.code === 11)
      {
        this.isPixPay = true;
        this.isShowInfoPix = false;
        this.clearAllIntervalActive();

        this.functions.showAlertSuccess(infoPix.msg);
        setTimeout(() => { this.functions.redirect('profile', 'order'); }, 5000);
      }
    });
  }

  createIntervalTimePix(infoPix: any): void
  {
    let dateExpired = new Date(infoPix.details.date_expired);
    let dateNow = new Date();
    let dias;
    let horas;
    let minutos;
    let segundos;

    const source = interval(1000);
    this.subscriptionTimer = source.subscribe(() => {
      dateExpired = new Date(infoPix.details.date_expired);
      dateNow = new Date();

      let segundosF = (dateExpired.getTime() - dateNow.getTime()) / 1000;

      // tslint:disable-next-line:radix
      dias = parseInt(String(segundosF / 86400));
      segundosF = segundosF % 86400;

      // tslint:disable-next-line:radix
      horas = parseInt(String(segundosF / 3600));
      segundosF = segundosF % 3600;
      // tslint:disable-next-line:radix
      minutos = parseInt(String(segundosF / 60));
      // tslint:disable-next-line:radix
      segundos = parseInt(String(segundosF % 60));

      if (minutos >= 0 || segundos >= 0)
      {
        this.contador = minutos + ':' + (segundos < 10 ? '0' + segundos : segundos);
      }
      else
      {
        this.contador = '00:00';
      }

      if (dateNow < dateExpired)
      {
        this.isCancelPix = false;
      }
      else
      {
        this.cancelPix();
      }
    });


    // VERIFICAÇÃO DE PAGAMENTO PIX
  /*this.verifyInterval = setInterval(() => {


    }, 1000);*/
  }

  getInfoPix(): void
  {
    this.order$ = this.service.getInfoPix(this.merchantInfo.merchantId, this.orderId);
    this.order$.subscribe(data => {
      console.log(data);
      if (data.code === 5)
      {
        this.functions.showAlertSuccess(data.msg);
        this.clearAllIntervalActive();

        this.isPixPay = true;
        setTimeout(() => { this.functions.redirect('profile', 'order'); }, 5000);
      }
      else if (data.code === 6)
      {
        this.functions.showAlertError(data.msg);
        this.cancelPix();
        this.isCancelPix = true;
        this.clearAllIntervalActive();
      }
    });
  }

  cancelPix(): void
  {
    this.order$ = this.service.cancelPix(this.merchantInfo.merchantId, this.orderId);
    this.order$.subscribe(cancelPix => {
      if (cancelPix.code === 1)
      {
        this.isCancelPix = true;
        this.clearAllIntervalActive();
      }
    });
  }


  clearAllIntervalActive(): void
  {
    this.subscriptionVerifyPix?.unsubscribe();
    this.subscriptionTimer?.unsubscribe();
  }

  openModalDetailsOrder(modal, id): void
  {
    this.functions.setItemLocal('orderID', id);

    this.modalService.open(modal);
  }


}
