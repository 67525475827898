import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {FormsModule} from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { Ng2IziToastModule } from 'ng2-izitoast';
import {NgxMaskModule} from 'ngx-mask';
import {NgbActiveModal, NgbButtonLabel, NgbModule, NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app.routing.module';
import {StarRatingModule} from 'angular-star-rating';
import {ProfileRootModule} from './profile/profile-root.module';
import {FooterBarModule} from './footer-bar/footer-bar.module';

import {SharedModule} from './shared/shared.module';

import {RouterModule} from '@angular/router';

import {StickyModule} from 'ng2-sticky-kit';



import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import {HomeComponent} from './home/home.component';
import { MenuComponent } from './menu/menu.component';


import { CartComponent } from './cart/cart/cart.component';
import { PaymentComponent } from './payment/payment.component';
import { ReviewComponent } from './review/review.component';
import { OrdersComponent } from './orders/orders.component';

import { SpinnerComponent } from './spinner/spinner.component';
import { AddressComponent } from './address/address.component';
import { SignupComponent } from './signup/signup.component';
import { MapsComponent } from './maps/maps.component';
import { CustomPageComponent } from './custom-page/custom-page.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ReviewAddComponent } from './review-add/review-add.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NetworkErroComponent } from './network-erro/network-erro.component';
import { ScrollSpyDirective } from './directive/scroll-spy.directive';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { FooterComponent } from './footer/footer.component';
import { LoadingComponent } from './loading/loading.component';
import { CartLateralComponent } from './cart/cart-lateral/cart-lateral.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CartModalComponent } from './cart/cart-modal/cart-modal.component';
import { CartPaymentComponent } from './cart/cart-payment/cart-payment.component';
import { NavHeaderPaymentComponent } from './nav-header-payment/nav-header-payment.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import { RouteModalComponent } from './modals/route-modal/route-modal.component';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import {CurrencyMaskInputMode, NgxCurrencyModule} from 'ngx-currency';

import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from 'ng-lazyload-image';
import { InputAutoFocusDirective } from './directive/input-auto-focus.directive';
import { AguardandoPagamentoComponent } from './aguardando-pagamento/aguardando-pagamento.component';
import { OrderPendingPaymentComponent } from './order-pending-payment/order-pending-payment.component';
import { OrderViewComponent } from './aguardando-pagamento/order-view/order-view.component';
import { AddressZipcodeComponent } from './address-zipcode/address-zipcode.component';
import {QRCodeModule} from 'angularx-qrcode';


export const customCurrencyMaskConfig = {
    align: 'right',
    allowNegative: true,
    allowZero: true,
    decimal: ',',
    precision: 2,
    prefix: 'R$ ',
    suffix: '',
    thousands: '.',
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.FINANCIAL
};


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ScrollSpyDirective,
        HomeComponent,
        MenuComponent,
        CartComponent,
        PaymentComponent,
        ReviewComponent,
        OrdersComponent,
        SpinnerComponent,
        AddressComponent,
        SignupComponent,
        MapsComponent,
        CustomPageComponent,
        OrderDetailsComponent,
        ReviewAddComponent,
        ForgotPasswordComponent,
        NetworkErroComponent,
        ScrollSpyDirective,
        NavHeaderComponent,
        FooterComponent,
        LoadingComponent,
        CartLateralComponent,
        NotFoundComponent,
        CartPaymentComponent,
        CartModalComponent,
        NavHeaderPaymentComponent,
        RouteModalComponent,
        InfoModalComponent,
        ScrollToTopComponent,
        ScrollToTopComponent,
        InputAutoFocusDirective,
        AguardandoPagamentoComponent,
        OrderPendingPaymentComponent,
        OrderDetailsComponent,
        OrderViewComponent,
        AddressZipcodeComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        Ng2IziToastModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NgbModule,
        NgbRatingModule,
        Ng2PageScrollModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgxMaskModule.forRoot(),
        StarRatingModule.forRoot(),
        ProfileRootModule,
        FooterBarModule,
        StickyModule,
        SharedModule,
        AppRoutingModule,
        LazyLoadImageModule,
        NgbModule,
        QRCodeModule
    ],
    exports: [
        RouterModule,
        PaymentComponent,
        CustomPageComponent
    ],
    providers: [
        FooterBarModule,
        NgbButtonLabel,
        NgbActiveModal,
        AppRoutingModule,
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
