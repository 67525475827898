<div class="mt-menu-header">
  <div class="d-none">
    <div class="bg-primary fixed-top border-bottom p-3 d-flex align-items-center">
      <a class="toggle togglew toggle-2" href="#"><span></span></a>
      <h4 class="font-weight-bold m-0 text-white">Minha Conta</h4>
    </div>
  </div>
  <!-- profile -->

  <div class="container position-relative">
    <div class="py-4 row">
      <div class="col-md-4 col-sm-5 mb-3">
        <div class="bg-white rounded shadow-sm sticky_sidebar overflow-hidden">

          <div class="d-flex align-items-center p-3 border-bottom" *ngIf="functions.isLogin()">
            <div class="right">
              <h6 class="mb-2 font-weight-bold">{{userProfile.full_name}} <i class="feather-check-circle text-success"></i></h6>
              <p class="text-muted mb-2 small">{{userProfile.email_address}}</p>
              <p class="m-0"><i class="feather-phone"></i>
                {{userProfile.contact_phone | mask: '(00) 00000-0000'}}
              </p>
            </div>
            <div class="profile-buttons-mobile ml-auto left">
              <a class="btn btn-outline-secondary small m-1 p-1"  *ngIf="functions.isLogin()" href="javascript:;" (click)="showComponent('account')"> 
                <i class="feather-user mr-1 h6"></i>CONTA
              </a>
              <a class="btn btn-outline-secondary small m-1 p-1" *ngIf="functions.isLogin()" href="javascript:;" (click)="showComponent('address')"> 
                <i class="feather-map-pin mr-1 h6"></i>ENDEREÇOS
              </a>
            </div>
          </div>


          <!-- profile-details -->
          <div class="bg-white profile-details">

            <a *ngIf="!functions.isLogin()" href="javascript:;" class="d-flex w-100 align-items-center border-bottom p-3 mt-4" (click)="openLogin()">
              <div class="left mr-3">
                <h6 class="font-weight-bold mb-1 text-dark"> <i class="feather-file-text mr-1"></i> Efetuar Login</h6>
                <p class="small text-muted m-0">Efetuar Login</p>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>

            <a *ngIf="!functions.isLogin()" href="javascript:;" class="d-flex w-100 align-items-center border-bottom p-3" (click)="openSignUp()">
              <div class="left mr-3">
                <h6 class="font-weight-bold mb-1 text-dark"> <i class="feather-file-text mr-1"></i> Criar Conta</h6>
                <p class="small text-muted m-0">Criar Conta</p>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>

            <a *ngIf="functions.isLogin()" href="javascript:;" class="d-flex w-100 align-items-center border-bottom p-3" (click)="showComponent('order')">
              <div class="left mr-3">
                <h6 class="font-weight-bold mb-1 text-dark"> <i class="feather-file-text mr-1"></i> Meus Pedidos</h6>
                <p class="small text-muted m-0">Histórico de pedidos</p>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>
            <a *ngIf="functions.isLogin()" href="javascript:;" class="d-flex w-100 align-items-center border-bottom p-3" (click)="showComponent('account')">
              <div class="left mr-3">
                <h6 class="font-weight-bold mb-1 text-dark"><i class="feather-user mr-1"></i> Minha Conta</h6>
                <p class="small text-muted m-0">Nome, celular, senha...</p>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>
            <a *ngIf="functions.isLogin()" href="javascript:;"  class="d-flex w-100 align-items-center border-bottom p-3" (click)="showComponent('address')">
              <div class="left mr-3">
                <h6 class="font-weight-bold mb-1 text-dark"><i class="feather-map-pin mr-1"></i> Meus Endereços</h6>
                <p class="small text-muted m-0">Adicionar ou Remover endereço</p>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>

            <a href="javascript:;" class="d-flex w-100 align-items-center border-bottom px-3 py-4" (click)="showComponent('contact')">
              <div class="left mr-3 d-flex">
                <h6><i class="feather-phone bg-primary text-white p-2 rounded-circle mr-2"></i></h6>
                <h6 class="font-weight-bold m-0 text-dark"> Contato</h6>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>
            
            <a href="javascript:;" class="d-flex w-100 align-items-center border-bottom px-3 py-4" *ngFor="let pageG of pagesGeneric" (click)="functions.redirectPageCustom(pageG.page_id)">
              <div class="left mr-3 d-flex">
                <h6 class="m-0"><i class="{{pageG.icon}} text-white p-2 rounded-circle mr-2"></i></h6>
                <h6 class="font-weight-bold m-0 text-dark"> {{pageG.title}}</h6>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>

            <!--PAGE CUSTOM FROM MERCHANT-->
            <a *ngFor="let page of pageGet" href="javascript:;" class="d-flex w-100 align-items-center px-3 py-4" (click)="functions.redirectPageCustom(page.page_id)">
              <div class="left d-flex mr-3">
                <h6><i class="{{page.icon}} text-white p-2 rounded-circle mr-2"></i></h6>
                <h6 class="font-weight-bold m-0 text-dark"> {{page.title}}</h6>
              </div>
              <div class="right ml-auto">
                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
              </div>
            </a>
          </div>
        </div>
      </div>


      <!-- CONTEUDO DIREITA - PROFILE -->
      <div class="col-md-8 col-sm-7 mb-3">
        <div class="mb-5 mb-sm-1 rounded shadow-sm p-3 p-sm-4 bg-white">
          <!-- PROFILE -->
            <app-account *ngIf="route.account"></app-account>
            <app-address *ngIf="route.address"></app-address>
            <app-order-profile *ngIf="route.order"></app-order-profile>
            <app-contact *ngIf="route.contact"></app-contact>
            <app-custom-page *ngIf="route.customPage"></app-custom-page>
        </div>
      </div>
    </div>
  </div>
</div>

