import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



import {NgxMaskModule} from 'ngx-mask';
import {FooterBarModule} from '../footer-bar/footer-bar.module';
import {ProfileRootComponent} from './profile-root.component';
import {ProfileRoutingModule} from './profile.routing.module';
import {ProfileComponent} from './profile/profile.component';
import { ContactComponent } from './contact/contact.component';
import { AccountComponent } from './account/account.component';
import { AddressComponent } from './address/address.component';
import { OrderProfileComponent } from './order-profile/order-profile.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { CustomPageComponent } from './custom-page/custom-page.component';
import { LoadingComponent } from './loading/loading.component';
import {SharedModule} from '../shared/shared.module';




@NgModule({
  declarations: [
    ProfileRootComponent,
    ProfileComponent,
    ContactComponent,
    AccountComponent,
    AddressComponent,
    OrderProfileComponent,
    OrderDetailsComponent,
    CustomPageComponent,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    NgxMaskModule,
    FooterBarModule,
    NgbModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  providers: []
})
export class ProfileRootModule { }
