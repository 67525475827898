<div class="modal-address-search" >
  <div class="modal-header p-0 d-block">
    <div *ngIf="step === 1" class="search_location m-0 p-0 bg-primary shadow">
      <div class="p-3 d-flex align-items-center">
        <p class="m-0 text-white h6">Onde vamos entregar seu pedido?</p>
        <a class="hc-nav-back text-white ml-auto" (click)="closeModal()">
          <span><i class="feather-x h4"></i></span>
        </a>
      </div>
      <div id="step-1" class="input-group rounded px-3 pb-3">
        <div class="input-group-prepend">
          <button class="border-0 btn btn-outline-secondary text-dark bg-white btn-block" ><i class="feather-search"></i></button>
        </div>
        <input
          [(ngModel)]="qryInput"
          type="text"
          class="shadow-none border-0 form-control pl-0"
          id="pac-input"
          placeholder="Digite o endereço com número, cidade"
          (keyup)="getQuery()"
          autofocus
          [disabled]="msgErrorMap"
        >
        <!--<input #qryInput
               type="text"
               class="shadow-none border-0 form-control pl-0"
               id="pac-input"
               placeholder="Digite o endereço com número, cidade"
               aria-label=""
               aria-describedby="basic-addon1"
               (keyup)="getQuery($event.target.value)"
        >-->
      </div>
    </div>
    <div *ngIf="msgErrorMap" class="row my-3 justify-content-center align-items-center">
      <div class="h4 text-danger">{{msgErrorMap}}</div>
    </div>
  </div>

  <div *ngIf="showInfoMerchant">

    <div class="modal-body-70vh">
      <!-- LOCALIZAÇÃO DO ESTABELECIMENTO -->
      <div *ngIf="step3" class="p-3 mt-9 border-bottom">
        <h6>Adicione seu endereço no campo acima </h6>
        <hr>
        <div class="d-flex flex-column mb-3">
          <h5 class="font-weight-bold d-flex">{{merchantInfo.merchantName}}</h5>
          <p class="mb-0 small text-muted"><i class="feather-map-pin"></i>
            {{merchantInfo.street}} {{merchantInfo.number ? ', '+merchantInfo.number : ''}}
            <br>
            <small>{{merchantInfo.bairro}} - {{merchantInfo.city}} - {{merchantInfo.state}}</small>
          </p>
        </div>
        <p class="text-muted">RETIRAR ou ENTREGAR em:</p>
        <a href="javascript:;" class="text-decoration-none">
          <h5 class="text-primary m-0"><i class="feather-navigation"></i> {{merchantInfo.city}} - {{merchantInfo.state}}</h5>
        </a>
        <small class="text-muted pt-2">*Consulte área e taxas</small>
        <hr *ngIf="!functions.isLogin()">
        <div *ngIf="!functions.isLogin()" class="text-center">
            Já possui conta?
            <a class="btn btn-outline-info ml-2" href="javascript:;" (click)="openLogin()">
              Fazer o login
            </a>
          </div>
      </div>
    </div>
  </div>

  <div class="modal-body-70vh" *ngIf="!loadingChangeAddress">
    <!-- LISTA DE ENDEREÇOS CADASTRADOS  -->
    <div *ngIf="step3 && functions.isLogin()">
      <div *ngIf="apiResponse$ | async; else loadingCadAdd" class="osahan-cart-item p-3">
        <h6 class="">Endereços cadastrados</h6>
        <div ngbRadioGroup name="address" [(ngModel)]="defaultAddress" >
          <div class="custom-control mb-2 px-0" *ngFor="let address of addressList" (click)="changeAddressDelivery(address.address)">
            <label ngbButtonLabel class="d-flex border p-2 p-sm-3 osahan-check w-100 rounded border-primary" >
              <p>
                <i class="feather-home mr-sm-2 h6" *ngIf="address.location_name === 'CASA'"></i>
                <i class="feather-briefcase mr-sm-2 h6" *ngIf="address.location_name === 'TRABALHO'"></i>
              </p>
              <div>
                <p class="text-left mb-0 sm-none"><b>{{address.location_name}}</b></p>
                <p class="text-left mb-0">{{address.street}}, {{address.number}} <br>
                  <small class="text-muted">{{address.bairro}} {{address.complemento ? ' - ' + address.complemento : '' }} - {{address.city}} - {{address.state}}</small>
                </p>
              </div>
            </label>
          </div>
        </div>
      </div>
      <ng-template #loadingCadAdd>
        <app-loading></app-loading>
      </ng-template>
    </div>

  <!--HISTORICO ENDEREÇOS PESQUISADOS-->
    <div *ngIf="step3">
      <div *ngIf="localAddressList" class="osahan-cart-item p-3 border-top">
        <div class="d-flex mb-2">
          <h6>Endereços Pesquisado</h6>
          <div class="ml-auto">
            <button class="btn btn-sm btn-outline-secondary  small px-1 px-sm-2" (click)="cleanAddressTmp()">Limpar pesquisa</button>
          </div>
          
        </div>
        <div ngbRadioGroup name="address" >
          <div class="custom-control  mb-2 px-0" *ngFor="let localAddress of localAddressList; let i = index" (click)="changeAddressTmp(i)">
            <label ngbButtonLabel class="border d-flex p-sm-3 w-100 rounded border-primary" for="_{{localAddress}}">
              <p>
                <i class="feather-home mr-sm-2 h6" *ngIf="localAddress.location_name === 'CASA'"></i>
                <i class="feather-briefcase mr-sm-2 h6" *ngIf="localAddress.location_name === 'TRABALHO'"></i>
              </p>
              <div>

                <p class="text-left mb-0 sm-none"><b>{{localAddress.location_name}}</b></p>
                <p class="text-left mb-0">{{localAddress.street}}, {{localAddress.number}} <br>
                  <small class="text-muted">{{localAddress.bairro}} {{localAddress.complemento ? ' - ' + localAddress.complemento : '' }} - {{localAddress.city}} - {{localAddress.state}}</small>
                </p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-loading *ngIf="loadingChangeAddress"></app-loading>

  <!--RESULT SEARCH ADDRESS INPUT-->
  <ul *ngIf="step === 1" id="resultAddress" class="list-group mt-10 list-result-address" (click)="selectAddress($event)">
  </ul>
  <!--END RESULT-->

  <!--STEP 2 SEARCH ADDRESS INPUT-->
  <div [style.display]="step === 2 ? 'block' : 'none'" id="step-2" class="roudned">

    <form
            #formSearchAddress="ngForm"
            (ngSubmit)="registerAddress(formSearchAddress)"
            [class.was-validated]="validate"
    >
      <div class="float w-100 h-100" *ngIf="loadingDraged">
        <div class="row justify-content-center align-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="position-relative">
        <div class="backgroud-degrade w-100 p-3 pb-4 zindex-10 position-absolute ">

          <a class="btn btn-sm btn-outline-primary font-weight-bold shadow bg-white" (click)="backStep1()">
            <i class="feather-chevron-left"></i> VOLTAR
          </a>

        </div>

        <div id="map"></div>

        <div class="form-row p-3 mb-11" >
          <div class="col-12 my-2">
            <h5 class="m-0" *ngIf="saveAddress.street">{{saveAddress.street}}, {{saveAddress.number}}</h5>
            <small class="text-muted" *ngIf="saveAddress.bairro">{{saveAddress.bairro}} - {{saveAddress.city}} - {{saveAddress.state}}</small>
          </div>
          <div class="col-4 form-group">
            <label class="form-label" [innerHTML]="verifyBrasilia ? 'Quadra' : 'Número'"></label>
            <input [placeholder]="verifyBrasilia ? 'Quadra' : 'Número'" name="number" type="text" class="form-control" [(ngModel)]="streetNumber" required>
          </div>
          <div class="col-7 form-group">
            <label class="form-label">Complemento</label>
            <input placeholder="Bloco / Andar / Escritório / Apto / Depto" type="text" name="complemento" class="form-control" [(ngModel)]="saveAddress.complemento" [required]="verifyBrasilia">
          </div>

          <div class="col-7 form-group">
            <label class="form-label">Ponto de Referência</label>
            <input placeholder="" type="text" name="reference" class="form-control" [(ngModel)]="saveAddress.reference" [required]="verifyBrasilia">
          </div>

          <div class="mb-0 col-8 col-md-6 form-group">
            <label class="form-label">Tipo de endereço:</label>
            <div ngbRadioGroup name="locationName" class="btn-group btn-group-toggle w-100" [(ngModel)]="locationName" (change)="locationNameChange($event)">
              <label ngbButtonLabel for="casa" class="btn btn-outline-secondary">
                <input ngbButton type="radio" id="casa" [value]="'CASA'"><i class="feather-home"></i> Casa
              </label>
              <label ngbButtonLabel for="trabalho" class="btn btn-outline-secondary">
                <input ngbButton type="radio" id="trabalho" [value]="'TRABALHO'"><i class="feather-briefcase"></i> Trabalho
              </label>
            </div>
          </div>
          <div class="form-group col-4 mb-0">
            <label class="form-label"></label>
            <div class="custom-control custom-checkbox ml-3 mt-2">
              <input
                      #as_def
                      type="checkbox"
                      name="as_default"
                      id="as_default"
                      class="custom-control-input"
                      (click)="checkDefault(as_def)"
              >
              <label class="custom-control-label d-block pt-1" for="as_default">Endereço Padrão?</label>
            </div>
          </div>
        </div>
        <ng-template #loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
      <div class="p-3">
        <button type="submit" class="btn btn-primary btn-block btn-lg" [disabled]="confirmLoading">
          <div class="spinner-border" role="status" *ngIf="confirmLoading">
            <span class="sr-only">Loading...</span>
          </div>
          Confirmar Endereço <i class="icofont-long-arrow-right"></i>
        </button>
      </div>
    </form>
  </div>


  <!--END STEP 2 SEARCH ADDRESS INPUT-->

</div>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>


