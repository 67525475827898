import { Injectable } from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {Router} from '@angular/router';

import { v5 as uuidv5 } from 'uuid';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartService} from './new/cart.service';
import {ProfileService} from '../profile/profile.service';
import {environment} from '../../environments/environment';





@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  production = environment.production;
  constructor(
    private iziToast: Ng2IzitoastService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) { }



  toObject(arr): object
  {
    /*const rv = new HttpParams();*/
    const rv = {};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < arr.length; i++)
    {
      rv[arr[i].name] = arr[i].value;
      /*rv.append(arr[i].name, arr[i].value );*/
    }
    return rv;
  }

  createParams(arr): string
  {
    let params = '';
    for (const [key, value] of Object.entries(arr))
    {
      params += `&${key}=${value}`;
    }
    return params;
  }

  prettyPrice(value: any): string
  {
    let price = '';
    if (value !== null || value !== '' || value !== 0)
    {
      price = `${Number(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}`;
    }
    return price;
  }

  showAlertError(t: string = '', msg: string = ''): void
  {
    this.iziToast.destroy();
    this.iziToast.error({
      timeout: 5000,
      title: t,
      position: 'center',
      message: msg,
    });
  }

  showAlertErroServer(): void
  {
    this.showAlertError('Erro', 'Houve um problema em nossos servidores, tente novamente.');
  }

  showAlertSuccess(t: string = '', msg: string = ''): void
  {
    this.iziToast.destroy();
    this.iziToast.success({
      timeout: 3000,
      title: t,
      position: 'center',
      message: msg,
    });
  }

  showAlertWarning(t: string = '', msg: string = ''): void
  {
    this.iziToast.destroy();
    this.iziToast.warning({
      timeout: 3000,
      title: t,
      position: 'center',
      message: msg,
    });
  }

  scrollPage(element: string, offset: number = 90, body: any = ''): void
  {
    const yOffset = -offset;
    let e = document.querySelector('#' + element).getBoundingClientRect().top + + window.pageYOffset + yOffset;
    this.log(e);
    if (body)
    {
      e = document.querySelector(`#${body.id} #${element}`).getBoundingClientRect().top + + window.pageYOffset + yOffset;
      body.scroll({top: e, behavior: 'smooth'});
    }
    else
    {
      window.scrollTo({top: e, behavior: 'smooth'});
    }
    this.log(e);
    /*document.querySelector(element).scrollIntoView({behavior: 'smooth'});*/
  }

  goToTop(): void
  {
    window.scrollTo({top: 10, behavior: 'smooth'});
  }


  redirect(route: string, params: any = false): void
  {
    if (params)
    {
      this.router.navigate([`/${route}`, params]);
    }
    else
    {
      this.router.navigate([`/${route}`]);
    }
  }

  refresh(route: string, param: any = false): void
  {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${route}`, param]);
    });
  }

  getUUID(key): string
  {
    const code = 'aa7cd38e-66f0-5698-9cac-cf8d890acf09';
    const date = new Date();
    return uuidv5(date.getTime() + '_' + key, code);
  }

  log(msg): any
  {
    if (!this.production)
    {
      console.log(msg);
    }
  }


  isVisit(): boolean
  {
    if (this.getItemLocal('token'))
    {
      if ( this.getItemLocal('token').split('_').length === 1)
      {
        return false;
      }
      else
      {
        return true;
      }
    }
    else
    {
      return false;
    }
  }

  isLogin(): boolean
  {
    if (this.getItemLocal('token'))
    {
      if ( this.getItemLocal('token').split('_').length === 1)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  closeModal(): void
  {
    this.modalService.dismissAll();
  }

  setItemLocal(name: string, item: string): void
  {
    localStorage.setItem(name, item);
  }

  getItemLocal(name: string): string
  {
    return localStorage.getItem(name);
  }

  removeItemLocal(name: string): void
  {
    localStorage.removeItem(name);
  }


  sanitizerLink(link): any
  {
    return this.sanitizer.bypassSecurityTrustUrl(link);
  }

  editItemCart(categoryId, itemId, key): void
  {
    /*const id = `${categoryId}_${itemId}_${key}`;
    if (id)
    {
      this.setItemLocal('passId', id);
    }
    CartService.emitterAddCart.emit(id);
    this.log(id);*/
  }

  redirectPageCustom(identify: string): void
  {
    this.setItemLocal('pageCustom', identify);
    this.refresh('profile', 'customPage');
    ProfileService.emitterCustomPage.emit(true);
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  isOpen(): boolean
  {
    if (this.getItemLocal('open') && this.getItemLocal('open') === '777')
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  getDate(time: boolean = false): string
  {
    const currentdate = new Date();
    if (time)
    {
      return  currentdate.getHours() + ':' + (currentdate.getMinutes() < 10 ? '0' + currentdate.getMinutes() : currentdate.getMinutes()) + ':' + (currentdate.getSeconds() < 10 ? '0' + currentdate.getSeconds() : currentdate.getSeconds());
    }
    else
    {
      return currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1)  + '-' + currentdate.getDate();
    }
  }

}
