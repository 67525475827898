import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../services/api.service';

@Component({
  selector: 'app-order-pending-payment',
  templateUrl: './order-pending-payment.component.html',
  styleUrls: ['./order-pending-payment.component.css']
})
export class OrderPendingPaymentComponent implements OnInit {

  orders: any;
  orders$: Observable<any>;

  constructor(
      private service: ApiService
  )
  {
    this.orders$ = new Observable<any>();
  }

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders(): void
  {
    this.orders$ = this.service.getOrdersPendingPayment();
    this.orders$.subscribe(data => {
      if (data.code === 1)
      {
        this.orders = data.details[0];
      }
    }, error => {
      console.log(error);
    });
  }

}
