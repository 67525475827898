import {Component, Input, OnInit} from '@angular/core';
import {UserSignup} from '../class/user';
import {ApiService} from '../services/api.service';
import {FunctionsService} from '../services/functions.service';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ActivatedRoute} from '@angular/router';
import {Merchant} from '../class/merchant';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  userSignup: UserSignup;
  param: string;
  merchant: Merchant;
  merchant$: Observable<ApiResponse>;

  apiResponse: ApiResponse;
  service$: Observable<ApiResponse>;

  awaitLayout: boolean;

  constructor(
    private service: ApiService,
    private functions: FunctionsService,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal)
  {
    this.userSignup = new UserSignup();
    this.param = this.route.snapshot.params[`route`];
  }

  ngOnInit(): void {
    this.getMerchantInfo();
  }

  getMerchantInfo(): void
  {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.functions.log('Armazenado');
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
    }
    else
    {
      this.functions.log('Requisição');
      this.merchant$ = this.service.getMerchantInfo();
      this.merchant$.subscribe(data => {
        if (data.code === 1)
        {
          this.merchant = data.details.data;
        }
      });
    }
  }

  changeInput(inp): void
  {
    inp.style.border = '';
  }

  signup(form): void
  {
    const nome = document.querySelector('#name') as HTMLInputElement;
    const email = document.querySelector('#email') as HTMLInputElement;
    const cell = document.querySelector('#contact_phone') as HTMLInputElement;
    const pass = document.querySelector('#password') as HTMLInputElement;
    const cpass = document.querySelector('#cpassword') as HTMLInputElement;

    this.functions.log(nome);
    /*VALIDAÇÃO EXTRA FORMULARIO*/
    if (!this.userSignup.first_name)
    {
      this.functions.showAlertError('Nome é obrigatório');
      nome.style.border = '1px solid red';
      return;
    }

    if (!this.userSignup.email_address)
    {
      this.functions.showAlertError('E-mail é obrigatório');
      email.style.border = '1px solid red';
      return;
    }

    if (!this.userSignup.contact_phone)
    {
      this.functions.showAlertError('Celular é obrigatório');
      cell.style.border = '1px solid red';
      return;
    }

    if (!this.userSignup.password)
    {
      this.functions.showAlertError('Senha é obrigatório');
      pass.style.border = '1px solid red';
      return;
    }

    if (!this.userSignup.cpassword)
    {
      this.functions.showAlertError('Repita a senha');
      cpass.style.border = '1px solid red';
      return;
    }

    if (this.userSignup.password !== this.userSignup.cpassword)
    {
      this.functions.showAlertError('As senhas informadas não são iguais');
      return;
    }

    /*END VALIDAÇÃO EXTRA FORMULARIO*/
    // ---------------------------------------------------

    this.userSignup.first_name = this.userSignup.first_name.trim();
    const stringLength = this.userSignup.first_name.length;
    const last = this.userSignup.first_name.split(' ');
    if (last.length < 2)
    {
      this.functions.showAlertError('Digite Seu Nome Completo');
      nome.style.border = '1px solid red';
      return;
    }
    this.userSignup.last_name = last[last.length - 1];
    this.userSignup.first_name = '';
    for (const [k, v] of Object.entries(last))
    {
      if (Number(k) < last.length - 1)
      {
        this.userSignup.first_name += v + ' ';

      }
    }
    this.functions.log(this.param);
    this.service.responseSignUp(this.userSignup, this.param);
  }

}
