<section class="mt-menu-header section-payment-confirm pt-3">
    <div class="container position-relative ">
        <div class="payment-order-content mb-4" id="sidebar_fixed0">
            
                <div class="row" id="receipt-content">
                    <div class="col-sm-6 col-12 pt-3">
                        <h5>{{merchantInfo.merchantName}}</h5>
                        <h6 class="contact_phone">{{merchantInfo.contactPhone | mask: '(00) 00000-0000'}}</h6>
                        <hr>
                        <div class="box-order-header">
                            <div>
                                <small>{{order?.delivery_date | date: 'dd/MM/yyyy'}}</small><br>
                                <span class="h6">{{order?.delivery_time }}</span>
                            </div>
                            <div>
                                <small>PEDIDO</small><br>
                                <span class="h6">{{order?.order_id}}</span><br>
                            </div>
                            <div>
                                <small>TOTAL</small><br>
                                <span class="h6">{{order?.total_w_tax | prettyPrice}}</span>
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm btn-outline-secondary view-receipt-front small p-1" (click)="openModalDetailsOrder(modalOrderDetails, order.order_id)">VER<br> PEDIDO</button>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <div class="col-sm-6 col-12 area-pix">
                        <div *ngIf="!isCancelPix && !isShowInfoPix && !isPixPay" class="d-flex justify-content-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div *ngIf="isShowInfoPix && !isCancelPix && !isPixPay" id="__section_pix">
                            <p>Escanei o QR Code para pagar</p>
                            <div class="h4" id="__contador">{{this.contador}}</div>
                            <div id="qrcode-pix">
                                <qrcode [qrdata]="pixInfo?.pix_key" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
                            </div>
                            <!--- <span id="qrcode-text"></span>
                            <hr>--->
                            <p>COPIA E COLA PARA PAGAR</p>
                            <button type="button" class="btn btn-outline-primary" id="btnCopyQrCode" (click)="copyQrCode()">COPIAR CÓDIGO</button>
                        </div>

                        <div *ngIf="isCancelPix" class="row align-items-center justify-content-center __sectionPixCancel" >
                            <div class="col-12 text-center py-3">
                                <div class="h4 text-danger">Pix cancelado por falta de pagamento</div>
                            </div>

                        </div>

                        <div *ngIf="isPixPay" class="row align-items-center justify-content-center __sectionPixConfirm">
                            <div class="icon icon--order-success svg add_bottom_15">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                                    <g fill="none" stroke="#8EC343" stroke-width="2">
                                        <circle cx="36" cy="36" r="35" style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px;"></circle>
                                        <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style="stroke-dasharray:50px, 50px; stroke-dashoffset: 0px;"></path>
                                    </g>
                                </svg>
                            </div>
                            <div class="col-12 text-center py-3">
                                <div class="h4 font-weight-bolder">Pagamento realizado com sucesso</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center col-12 my-4">
                        <a href="/" class="btn_1 outline">Voltar ao início</a>
                    </div>

                </div>

               
            
            
        </div>

    </div>
</section>
<ng-template #modalOrderDetails let-modal>
    <app-order-view></app-order-view>
</ng-template>
