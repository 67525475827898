import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AguardandoPagamentoService {

  url = environment.urlPayment;
  urlPayment = `${this.url}/payments/nbanking/orders/pix`;
  apiKey = 'g0s5-g3s5-K3a6-a9a12';
  headers: any;
  options: any;

  constructor(
      private http: HttpClient
  )
  {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${this.apiKey}`
    });

    this.options = { headers: this.headers};
  }

  getOrderInfo(orderId): Observable<any>
  {
    return this.http.get(`${this.url}/orders/${orderId}`, this.options);
  }

  createPix(merchantId, orderId): Observable<any>
  {
    const params = {
      merchantId,
      orderId
    };
    return this.http.post(`${this.urlPayment}/create`, params, this.options);
  }


  getInfoPix(merchantId, orderId): Observable<any>
  {
    const params = {
      merchantId,
      orderId
    };
    return this.http.post(`${this.urlPayment}/get`, params, this.options);
  }

  cancelPix(merchantId, orderId): Observable<any>
  {
    const params = {
      merchantId,
      orderId
    };
    return this.http.post(`${this.urlPayment}/delete`, params, this.options);
  }
}
