<div class="order-payment-pending-notify" *ngIf="orders">
    
        <p class="text-muted small mb-0">PEDIDO</p>
        <span class="font-weight-bold h6 my-1 mx-1">{{orders.order_id}}</span>
        <span id="badge_{{orders.order_id}}" class="badge-orders badge-<?= Yii::app()->functions->getBadgeStatus($orderPending[0]['status_code'])?>">
            {{orders.status}}
        </span>
        
        <a href="javascript:" [routerLink]="['/aguardando-pagamento', orders.order_id]" 
            class="btn small p-1 btn-warning">Efetuar <br> Pagamento
        </a>

</div>
