import {NgModule} from '@angular/core';

import { RouterModule, Routes} from '@angular/router';

import { HomeComponent } from './home/home.component' ;
import { LoginComponent } from './login/login.component';
import {PaymentComponent} from './payment/payment.component';
import {ProfileRootComponent} from './profile/profile-root.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NetworkErroComponent} from './network-erro/network-erro.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {PaymentGuard} from './guards/payment.guard';
import {AguardandoPagamentoComponent} from './aguardando-pagamento/aguardando-pagamento.component';


const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'payment', component: PaymentComponent, canActivate: [PaymentGuard], canDeactivate: [PaymentGuard]},
  {path: 'aguardando-pagamento/:orderId', component: AguardandoPagamentoComponent},
  {path: 'profile', component: ProfileRootComponent},
  {path: 'profile/:param', component: ProfileRootComponent},
  {path: 'networkError', component: NetworkErroComponent},
  {path: '**', component: NotFoundComponent},
];




@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]

})
export class AppRoutingModule {}
