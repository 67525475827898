  <div class="m-0 p-0">
    <div class="bg-primary shadow p-3 pb-5 d-flex header-review-detail">
      <h4 class=" pt-1 text-white ">AVALIAÇÕES</h4>
      <a class="hc-nav-back text-white ml-auto" (click)="activeModal.close()"><span><i class="feather-x h5"></i></span></a>
    </div>
    <div class="mb-3 mx-3 osahan-cart-item-profile bg-white shadow rounded p-3 mt-n5">
      <h5 class="font-weight-bold border-bottom pb-2 mb-2">{{merchant.merchantName}}</h5>
      <div class="d-flex">
        <p class="mb-0">Avaliação Geral<br>
          <small>{{merchant.ratings.votes}} Avaliações</small>
        </p>
        <div class="star-rating ml-auto">
          <div class="d-inline-block h6 m-0">
            <div>{{merchant.ratings.ratings}}</div>
            <ngb-rating [(rate)]="merchant.ratings.ratings" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
            <ng-template #t let-fill="fill">
                    <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                    </span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-8 mt-14">

    <!-- Lista de avaliações pendentes-->
    <h6 class="mx-3 pt-3" *ngIf="orderActive">Avalie os seus últimos pedidos</h6>
    <div class="bg-white rounded m-3 mb-4" *ngIf="orderActive">
      <div class="border-bottom mt-3 pb-2" *ngFor="let order of orderReview">
        <div class="d-flex align-items-center">
          <div>Avaliar pedido #{{order.order_id}}</div>
          <a href="javascript:;" class="btn btn-sm btn-outline-success ml-auto small" (click)="openModalReviewAdd(order.order_id)">AVALIAR AGORA</a>
        </div>
        <div class="d-flex">
          <div>{{order.date_create}} <small>{{order.hour_create}}</small></div>
          <a href="javascript:;" (click)="seeOrder(order.order_id + '_review')" class="ml-auto small mt-2" >VER PEDIDO <i class="feather-arrow-right"></i></a>
        </div>
      </div>
    </div>

    <!-- Lista de avaliações -->
    <h6 *ngIf="review" class="mx-3" [class.mt-13]="!orderActive">Últimas avaliações recebidas</h6>
    <div *ngIf="review" class="restaurant-detailed-ratings-and-reviews">
      <div class="reviews-members px-3 pt-2" *ngFor="let rev of review">

            <div class="reviews-members-header">
              <div class="star-rating float-right">
                <div class="d-inline-block">
                  <span>{{rev.rating}}</span>
                  <ngb-rating [(rate)]="rev.rating" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                  <ng-template #t let-fill="fill">
                    <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                    </span>
                  </ng-template>
                </div>
              </div>
              <h6 class="mb-0">{{rev.client_name}}</h6>
              <p class="text-muted small mb-1">{{rev.date}}</p>
            </div>
            <div class="reviews-members-body">
              <p class="">{{rev.review}}</p>
              <p class="pl-3 reviews-reply" *ngIf="rev.reply.length > 0">
                <small>RESPOSTA: <strong>{{rev.reply[0].reply_from}}</strong></small><br>
                {{rev.reply[0].review}}
              </p>
            </div>
            <hr>

      </div>
    </div>

  </div>





