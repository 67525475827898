import { Component, OnInit } from '@angular/core';
import {Merchant} from '../../class/merchant';
import {FunctionsService} from '../../services/functions.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  whatsapp: string;
  merchant: Merchant;

  constructor(private functions: FunctionsService) { }

  ngOnInit(): void {
    this.getMerchantInfo();
    const msgWhats = 'Olá, gostaria de informações sobre o Delivery';
    if (this.merchant.whatsapp === '1')
    {
      this.whatsapp = `https://api.whatsapp.com/send?phone=+55${this.merchant.contactPhone}&text=${msgWhats}`;
    }
    else
    {
      this.whatsapp = this.functions.sanitizerLink('javascript:;');
    }
  }

  getMerchantInfo(): void
  {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
    }
    else
    {
      this.functions.showAlertError('Erro nas informações do restaurante');
    }

  }

}
