    <div class="d-flex mb-4">
        <!--- <div class="logo-image-merchant-checkout rounded mr-2">
            <img class="img-fluid item-img" width="50">
        </div>-->
        <div>
            <h5 class="font-weight-bold">{{merchant.merchantName}}</h5>
            <p class="mb-0 text-muted"> {{merchant.address}}</p>
        </div>
    </div>
    
    <div class="flex-column">
            <p class="font-weight-bold text-black">Envie sua mensagem para nós que retornaremos para você em breve</p>
            <h6 class="font-weight-light text-dark mt-2 mb-3"><i class="feather-message-circle icon-color-01 mr-1"></i>
                <a [href]="whatsapp"> {{merchant.contactPhone | mask: '(00) 00000-0000'}}
                  <span *ngIf="merchant.whatsapp === '1'" class="font-weight-light badge badge-success small ml-1">
                    <small>CLIQUE PARA CHAMAR</small><br><i class="feather-message-circle mr-1"></i>WHATS APP
                  </span>
                </a>
            </h6>

            <!-- Formulário de contato -->
            <!--
            <form>
            &lt;!&ndash; se o usuário estiver logado, podemos puxar o nome dele aqui nos campos &ndash;&gt;
                <div class="form-group">
                    <label for="exampleFormControlInput1" class="small font-weight-bold">Nome</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Seu nome">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput2" class="small font-weight-bold">Email Address</label>
                    <input type="email" class="form-control" id="exampleFormControlInput2" placeholder="nome@email.com">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput3" class="small font-weight-bold">Telefone / WhatsApp</label>
                    <input type="number" class="form-control" id="exampleFormControlInput3" placeholder="(00) 0000-0000">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="small font-weight-bold">COMO PODEMOS AJUDAR? </label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Descreva aqui o que você precisa, dúvidas, sugestões ..." rows="3"></textarea>
                </div>
                <a class="btn btn-primary btn-block" href="#">ENVIAR</a>
            </form>-->

    </div>
 

