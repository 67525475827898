import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { v5 as uuidv5 } from 'uuid';
import { MD5 } from 'crypto-js';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Observable} from 'rxjs';


import {ApiResponse} from '../class/apiResponse';
import {environment} from '../../environments/environment';
import {User, UserProfile} from '../class/user';
import {take} from 'rxjs/operators';
import {FunctionsService} from './functions.service';

import {Order} from '../class/order';
import {Merchant} from '../class/merchant';
import {LoginService} from './login.service';
import {CartService} from './cart.service';
import {AddressService} from './address.service';
import {Meta} from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.apiUrl;
  private merchantKeys = environment.merchantKeys;
  private callback = `?json=true`;
  private params;
  private token: string;
  private devicePlatform: string;
  private browserVersion: string;
  private deviceID: string;
  private userAgent: string;
  private code = 'aa7c038e-60f0-5698-9cac-cf8d890acf09';
  private dateTimeCode: Date;
  public service$: Observable<ApiResponse>;
  private service2$: Observable<ApiResponse>;
  private service3$: Observable<ApiResponse>;


  private login$: Observable<ApiResponse>;
  cartCount$: Observable<ApiResponse>;


  private userProfile: UserProfile;


  merchant: Merchant;
  color$: Observable<ApiResponse>;
  color: any;
  colorApp: any;
  colorDefault: any;

  ready: boolean;

  constructor(
      private http: HttpClient,
      private deviceService: DeviceDetectorService,
      public functions: FunctionsService,
      private loginService: LoginService,
      private metaService: Meta)
  {
    this.devicePlatform = this.deviceService.device === 'Unknown' ? 'browser' : this.deviceService.device;
    this.browserVersion = this.deviceService.browser_version;
    this.userAgent = this.deviceService.userAgent;
    this.token = this.functions.getItemLocal('token') !== null ? this.functions.getItemLocal('token') : '';
    this.dateTimeCode = new Date();
    this.deviceID = uuidv5(this.userAgent + this.devicePlatform, this.code);
    this.params = `${this.callback}${this.merchantKeys}&device_id=${this.deviceID}&device_platform=${this.devicePlatform}${this.token !== '' ? '&token=' + this.token : ''}`;

/*    console.log('DEVICE PLATFORM: ' + this.devicePlatform);
    console.log('BROWSER VERSION: ' + this.browserVersion);
    console.log('TOKEN: ' + this.token);*/
    /*this.functions.log('DEVICE ID: ' + this.deviceID);
    this.functions.log('Device User Agent: ' + this.userAgent);*/
  }


  /*GET GENERICO*/
  get(action: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/${action}/${this.params}`).pipe();
  }

  // -----------------------------------

  /*USER SECTION*/
  private sendLogin(user: User): Observable<ApiResponse>
  {
    user.password = MD5(user.password).toString();
    return this.http.post<ApiResponse>(`${this.apiUrl}/login/${this.params}`, user).pipe(take(1));
  }

  public responseLogin(user: User, route: string = '', modal: any = ''): void
  {
    /*EFETUANDO LOGIN*/
    this.login$ = this.sendLogin(user) ;
    this.login$.subscribe(data => {
      /*LOGIN CORRETO*/
      if (data.code === 1)
      {
        this.changeToken(data.details.token);
        this.params = `${this.callback}${this.merchantKeys}&device_id=${this.deviceID}&device_platform=${this.devicePlatform}&token=${this.token}`;

        /*CARREGANDO QUANTIDADE NO CARRINHO*/
        this.loadCountOrder();

        /*PEGANDO INFORMAÇÕES DO USUARIO*/
        this.service2$ = this.getUserProfile();
        this.service2$.subscribe(d => {
          if (d.code === 1)
          {
            if (!this.functions.getItemLocal('deliveryAddress'))
            {
              this.functions.log('Não tem endereço local');
              let address = false;
              if (d.details.data.address)
              {
                address = d.details.data.address[0];
                // tslint:disable-next-line:prefer-for-of
                for (let c = 0; c < d.details.data.address.length; c++)
                {
                  if (d.details.data.address[c].as_default === '2')
                  {
                    address = d.details.data.address[c];
                  }
                }
              }
              this.service$ = this.checkDistanceMerchant(address);
              this.service$.subscribe(infoDistance => {
                this.functions.log(infoDistance);
                this.functions.setItemLocal('distanceInfo', JSON.stringify(infoDistance.details));
                this.functions.setItemLocal('deliveryCharge', infoDistance.details.delivery_fee);
                this.functions.setItemLocal('deliveryAddress', JSON.stringify(address));
                AddressService.addressEmitter.emit(true);
              });
            }
            this.userProfile = d.details.data;
            this.functions.setItemLocal('userProfile', JSON.stringify(this.userProfile));
            this.functions.showAlertSuccess('OK', data.msg);
          }
        }, error => {
          this.functions.log(error);
        }, () => {
          this.loginService.actionLogin();
          CartService.emitterAddCart.emit('updateCart');
          this.functions.closeModal();
        });
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
      this.functions.showAlertErroServer();
    }, () => {
      this.loginService.disabledButton();
    });
  }

  private signUp(user: any): Observable<ApiResponse>
  {
    const params = this.functions.createParams(user);
    return this.http.get<ApiResponse>(`${this.apiUrl}/customerRegister/${this.params}${params}`).pipe();
  }

  public responseSignUp(user: any, route: string, modal: any = ''): void
  {
    this.service$ = this.signUp(user);
    this.service$.subscribe(data => {
      data = data as ApiResponse;
      this.functions.log(data);
      if (data.code === 1)
      {
        this.functions.setItemLocal('token', data.details.token);
        this.token = data.details.token;
        this.params = `${this.callback}${this.merchantKeys}&device_id=${this.deviceID}&device_platform=${this.devicePlatform}&token=${this.token}`;
        this.loadCountOrder();

        this.service2$ = this.getUserProfile();
        this.service2$.subscribe(userProfile => {
          this.functions.log('Load Profile Client');
          this.functions.log(userProfile);
          if (userProfile.code === 1)
          {
            this.functions.setItemLocal('userProfile', JSON.stringify(userProfile.details.data));
            const visitAddress = this.functions.getItemLocal('deliveryAddress');
            if (visitAddress)
            {
              this.functions.log('tem endereço do visitante');
              this.service3$ = this.setDeliveryAddress(JSON.parse(visitAddress));
              this.service3$.subscribe(cart => {
                this.functions.log('Set Delivery Address');
                this.functions.log(cart);
                if (cart.code === 1)
                {
                  this.functions.removeItemLocal('visitAddress');
                  this.functions.setItemLocal('deliveryAddress', visitAddress);
                  this.functions.showAlertSuccess(data.msg);
                  AddressService.addressEmitter.emit(true);
                }
              }, error => {
                this.functions.log(error);
              }, () => {
                this.loginService.actionLogin();
                CartService.emitterAddCart.emit('updateCart');
                this.functions.closeModal();
              });
            }
            else
            {
              this.loginService.actionLogin();
              CartService.emitterAddCart.emit('updateCart');
              this.functions.closeModal();
              this.functions.showAlertSuccess(data.msg);
            }
          }
        }, error => {
          this.functions.showAlertError('Erro', 'Houve um problema em nossos servidores, tente novamente.')
        });
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
      this.functions.log(error);
      this.functions.showAlertError('Erro com o servidor... Tente novamente');
    });
  }

  public forgotPass(email: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/requestForgotPass/${this.params}&user_email=${email}`).pipe(take(1));
  }

  public getUserProfile(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getUserProfile/${this.params}`).pipe(take(1));
  }

  public saveChangePassword(passform: any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(`${this.apiUrl}/saveChangePassword/${this.params}`, passform).pipe(take(1));
  }

  public saveProfile(user: any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(`${this.apiUrl}/saveProfile/${this.params}`, user).pipe(take(1));
  }

  /*END USER SECTION*/

  // -----------------------------------

  /*HOME SECTION*/
  public getMerchantInfo(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getMerchantInfo/${this.params}`).pipe();
  }

  public getHoursDelivery(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getHoursDelivery/${this.params}`).pipe();
  }

  public getHoursPickup(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getHoursPickup/${this.params}`).pipe();
  }

  public getMerchantCloseStore(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getMerchantCloseStore/${this.params}`).pipe();
  }

  public getCategoryItens(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/loadCategory2/${this.params}`).pipe();
  }

  /*END HOME SECTION*/

  /*MENU SECTION*/
  public getMenu(catId: string, itemId: string, row: string = ''): Observable<ApiResponse>
  {
    let params = `${this.params}&item_id=${itemId}&cat_id=${catId}`;
    if (row !== '')
    {
      params += `&row=${row}`;
    }
    return this.http.get<ApiResponse>(`${this.apiUrl}/loadItemDetails/${params}`).pipe();
  }


  /*END MENU SECTION*/


  /*CART SECTION*/

  public addItemCart(params: any): Observable<ApiResponse>
  {
    const para = this.functions.toObject(params);
    return this.http.post<ApiResponse>(`${this.apiUrl}/addToCart/${this.params}`, para).pipe();
  }

  public loadCart(transactionType: string = ''): Observable<ApiResponse>
  {
    let p = '';
    if (transactionType !== '')
    {
      p = `&transaction_type=${transactionType}`;
    }
    return this.http.get<ApiResponse>(`${this.apiUrl}/loadCart/${this.params}${p}`).pipe();
  }

  public removeItemCart(row: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/removeCartItem/${this.params}&row=${row}`).pipe();
  }

  public clearCart(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/clearCart/${this.params}`).pipe();
  }
  /*END CART SECTION*/


  /*PAYMENT SECTION*/
  public payNow(payForm: any): Observable<ApiResponse>
  {
    const params = this.functions.createParams(payForm);
    return this.http.get<ApiResponse>(`${this.apiUrl}/payNow/${this.params}${params}`).pipe();
  }

  public setDeliveryAddress(form: any, visitant: boolean = false): Observable<ApiResponse>
  {
    let params = this.functions.createParams(form);
    if (!visitant)
    {
      params += '&save_address=1';
    }
    return this.http.get<ApiResponse>(`${this.apiUrl}/setDeliveryAddress/${this.params}${params}`).pipe(take(1));
  }

  public changeAddressCart(address: any): Observable<ApiResponse>
  {
    const params = this.functions.createParams(address);
    return this.http.get<ApiResponse>(`${this.apiUrl}/changeAddressCart/${this.params}${params}`).pipe();
  }

  public applyVoucher(voucher: string): Observable<ApiResponse>
  {
    const params = '&voucher_name=' + voucher;
    return this.http.get<ApiResponse>(`${this.apiUrl}/applyVoucher/${this.params}${params}`).pipe();
  }

  public removeVoucher(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/removeVoucher/${this.params}`).pipe();
  }

  /*END PAYMENT SECTION */


  /*ADDRESS SECTION*/
  public setAddressBook(id: string, contactPhone: string): Observable<ApiResponse>
  {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ApiResponse>(`${this.apiUrl}/setAddressBook/${this.params}&addressbook_id=${id}&contact_phone=${contactPhone}`).pipe(take(1));
  }

  public getDeliveryFee(address: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getDeliveryFee/${this.params}&delivery_address=${address}`).pipe(take(1));
  }


  public getAddressBookList(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getAddressBookList/${this.params}`).pipe();
  }

  public saveAddress(address: any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(`${this.apiUrl}/saveAddressBook/${this.params}`, address).pipe();
  }

  public checkDistanceMerchant(address: any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(`${this.apiUrl}/checkDistanceMerchant/${this.params}`, address).pipe();
  }

  public deleteAddress(address: any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(`${this.apiUrl}/deleteAddressBook/${this.params}`, address).pipe(take(1));
  }

  getAddressCEP(cep): Observable<any>
  {
    // https://brasilapi.com.br/api/cep/v2/{cep}
    return this.http.get<any>(`https://brasilapi.com.br/api/cep/v2/${cep}`).pipe();
  }

  /*END ADDRESS SECTION*/



  /*REVIEWS SECTION*/
  public loadReviews(limit: string = '10'): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/loadReviews/${this.params}&limit=${limit}`).pipe();
  }

  public addReview(review: any): Observable<ApiResponse>
  {
    const params = this.functions.createParams(review);
    return this.http.get<ApiResponse>(`${this.apiUrl}/addReview/${this.params}${params}`).pipe();
  }

  /*END REVIEWS SECTION*/

  /*LOAD SERVICES*/
  public getServicesList(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/servicesList/${this.params}`).pipe(take(1));
  }

  /*END SERVICES*/

  public getOrderById(orderId: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getOrderById/${this.params}&order_id=${orderId}`).pipe(take(1));
  }


  /*CUSTOM PAGES*/
  public getPagesGeneric(identify: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getPagesGeneric/${this.params}&page_id=${identify}`).pipe();
  }

  public getPagesById(id: string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getPagesByID/${this.params}&page_id=${id}`).pipe(take(1));
  }


  public loadCartCount(route = ''): void
  {
    this.cartCount$ = this.get('loadCartCount');
    this.cartCount$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.setItemLocal('cartCount', data.details);
        if (route)
        {
          this.functions.redirect(route);
        }
      }
      else
      {
        this.functions.setItemLocal('cartCount', '');
      }
    });
  }

  public loadCountOrder(): void
  {
    if (this.functions.isLogin() || this.functions.isVisit())
    {
      let countOrder = 0 ;
      this.service$ = this.get('getOrders');
      this.service$.subscribe(data => {
        if (data.details.data)
        {
          for (const [key, value] of Object.entries(data.details.data as Order))
          {
            if (value.status !== 'Pedido Finalizado / Entregue' && value.status !== 'Cancelado')
            {
              this.functions.log(value.status);
              countOrder += 1;
            }
          }

          if (countOrder === 0)
          {
            this.functions.setItemLocal('orderActiveCount', '');
          }
          else
          {
            this.functions.setItemLocal('orderActiveCount', countOrder.toString());
          }
        }
      });
    }
    else
    {
      this.functions.setItemLocal('orderActiveCount', '');
    }
  }


  public getColorsDefault(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getColorsDefault/${this.params}`).pipe(take(1));
  }

  public getColorsApp(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getColorsApp/${this.params}`).pipe(take(1));
  }

  public getOrdersPendingPayment(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/getOrdersPendingPayment/${this.params}`).pipe(take(1));
  }


  /*FUNCTIONS EXTRA*/
  changeToken(token): void
  {
    this.token = token;
    this.functions.setItemLocal('token', token);
  }

  logout(): void
  {
    this.token = 'visit_' + uuidv5(this.userAgent + this.devicePlatform + 'token_visit', this.code);
    this.functions.setItemLocal('token', this.token);

  }


  /*APP CONFIG*/
  loadDefaultColor(): void
  {
    this.color$ = this.getColorsDefault();
    this.color$.subscribe(data => {
      if (data.code === 1)
      {
        this.colorDefault = data.details;
      }
    }, error => {
      this.functions.log(error);
    }, () => {
      this.loadColorApp();
    });
  }

  loadColorApp(): void
  {
    this.color$ = this.getColorsApp();
    this.color$.subscribe(data => {
      if (data.code === 1)
      {
        this.colorApp = data.details;
      }
    }, error => {
      this.functions.log(error);
    }, () => {
      this.loadCountOrder();
      this.loadCartCount();

      if (this.functions.getItemLocal('merchantInfo'))
      {
        this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
      }

      if (this.merchant.optionsMerchant.custom_color === '1')
      {
        this.color = this.colorApp;
      }
      else
      {
        this.color = this.colorDefault;
      }

      document.querySelector('link').href = this.merchant.optionsMerchant.logo_app ? this.merchant.optionsMerchant.logo_app : this.merchant.logo1;


      /*DEFININDO CORES PARA O APP*/
      document.documentElement.style.setProperty('--dark-color-01', this.color.primary_color_1);
      document.documentElement.style.setProperty('--dark-color-02', this.color.primary_color_2);
      document.documentElement.style.setProperty('--dark-color-03', this.color.secondary_color_1);
      document.documentElement.style.setProperty('--dark-color-04', this.color.secondary_color_2);
      document.documentElement.style.setProperty('--light-color-01', this.color.light_color_1);
      document.documentElement.style.setProperty('--light-color-02', this.color.light_color_2);
      document.documentElement.style.setProperty('--dark-color-btn-01', this.color.dark_color_btn_1);
      document.documentElement.style.setProperty('--dark-color-btn-02', this.color.dark_color_btn_2);

      this.metaService.addTags([
        {name: 'theme-color', content: this.color.primary_color_1},
        {name: 'apple-mobile-web-app-status-bar-style', content: this.color.primary_color_1},
        {name: 'msapplication-navbutton-color', content: this.color.primary_color_1},
      ]);
    });
  }


  /*REVIEWS SECTION*/
  public loadMapKey(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(`${this.apiUrl}/loadMapKey/${this.params}`).pipe();
  }

}
