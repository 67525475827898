
<div class="modal-content">
    <div class="modal-header shadow">
        <h4 class="m-0">Minha Cesta</h4>
    </div>
    <app-loading *ngIf="cartService.loading"></app-loading>
    <div class="modal-body-70vh p-3" *ngIf="!cartService.cartEmpty && !cartService.loading">

        <div class="border-bottom py-2" *ngFor="let item of cartService.cartData.item | keyvalue; let i = index">
            <div class="d-flex list-card overflow-hidden position-relative">
                <div class="media menu-list-checkout w-100">
                    <div class="media-body">
                        <p class="mb-1 cart-item-name h6">{{item.value.qty}} x {{item.value.item_name}}</p>
                        <p class="price-card price-position text-black mb-0">
                            <small *ngIf="item.value.qty > 1" class="text-muted">
                                {{item.value.qty}} X <small>{{item.value.size_words}}</small> {{cartService.getNormalPrice(item.key, item.value.qty, true) | prettyPrice}} =
                            </small>
                            <span class="font-weight-bold">
                                <small *ngIf="item.value.qty === 1">{{item.value.size_words}}</small>
                                {{cartService.getNormalPrice(item.key, item.value.qty) | prettyPrice}}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="btn-add ml-auto">
                    <a class="btn p-0 text-danger" href="javascript:;" (click)="cartService.removeCartItem(item.key)"><i class="feather-x h6 font-weight-bold"></i></a>
                    <a class="btn p-0 " href="javascript:;" (click)="modalService.openEditItemModal(item.value.category_id, item.value.item_id, item.key)"> <i class="feather-edit h6 font-weight-bold"></i></a>
                </div>

            </div>
            <div class="d-block text-muted">
                <small [innerHTML]="cartService.subItemHtml[i]"></small>
            </div>

        </div>
        <!--VERIFICAÇÃO CASO NAO HAJA ITEM NO CARRINHO-->

        <div class="bg-white clearfix mt-3" *ngIf="cartService.cartData.total.total > 0">
            <p class="mb-1">Sub Total <span class="float-right text-dark">{{cartService.cartData.total.subtotal | prettyPrice}}</span></p>
            <p class="mb-1 text-success" *ngIf="cartService.cartDetails.voucher_details">Desconto <small class="small text-muted"> CUPOM <b>{{cartService.cartDetails.voucher_details.voucher_name}}</b></small><span class="float-right text-success">- {{cartService.cartData.total.less_voucher | prettyPrice}}</span></p>
            <p class="mb-1" *ngIf="cartService.transactionType === 'delivery'">
                <span *ngIf="cartService.deliveryFee">Taxa de entrega</span> 
                <span class="text-info ml-1"><i class="icofont-info-circle"></i></span>
                <span class="float-right text-dark ml-auto font-weight-bold" *ngIf="cartService.deliveryFee">
                    {{ cartService.deliveryFee | prettyPrice}}
                </span>
                <a href="javascript:;" class="btn btn-link small w-100" (click)="modalService.openAddress()" *ngIf="!cartService.deliveryFee">
                    TAXA DE ENTREGA <br><b>ADICIONE O ENDEREÇO</b>
                 </a>

            </p>

            <hr>
            <h6 class="font-weight-bold mb-0">TOTAL A PAGAR <span class="float-right">{{cartService.transactionType === 'delivery' ? functions.prettyPrice(cartService.cartData.total.total) : functions.prettyPrice(cartService.cartData.total.subtotal)}}</span></h6>
        </div>
    </div>

    <div *ngIf="!cartService.loading && cartService.cartEmpty" class="justify-content-center align-items-center text-center mt-14 py-5">
        <h5>{{'Sua Cesta está vazia'}}</h5>
    </div>

    <div class="modal-footer shadow-top"  *ngIf="cartService.cartData.total.total">
        <button
            class="btn btn-success btn-block btn-lg roudned"
            *ngIf="payment"
            class="btn btn-success btn-block btn-lg"
            type="button"
            (click)="checkoutService.validPayNow()"
            [disabled]="checkoutService.clickCancel"
        >
            <div class="spinner-border" role="status" *ngIf="checkoutService.clickCancel">
                <span class="sr-only">Carregando...</span>
            </div>
            {{txtBtn}} {{cartService.transactionType === 'delivery' ? functions.prettyPrice(cartService.cartData.total.total) : functions.prettyPrice(cartService.cartData.total.subtotal)}}
            <i class="feather-arrow-right"></i>
        </button>
        <a *ngIf="functions.isOpen() && !cartService.cartEmpty" class="btn btn-success btn-block" href="javascript:;" (click)="checkout()">{{txtBtn}} {{functions.prettyPrice(cartService.cartData.total.total)}} <i class="feather-arrow-right"></i></a>
        <p *ngIf="!functions.isOpen()" class="m-0 btn btn-block"><span class="badge badge-warning small">FECHADO AGORA</span></p>
        <span class="text-center p-0" *ngIf="cartService.cartData.total.total">
            <button type="button" class="btn btn-outline-secondary small" (click)="cartService.cleanCart()">ESVAZIAR CESTA</button>
        </span>
    </div>
</div>

