import { Component, OnInit } from '@angular/core';
import {PasswordForm, UserProfile} from '../../class/user';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {ApiService} from '../../services/api.service';
import {FunctionsService} from '../../services/functions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  userProfile: UserProfile;
  userProfile$: Observable<ApiResponse>;
  passwordForm: PasswordForm;
  passwordForm$: Observable<ApiResponse>;

  name: string;

  constructor(
    private service: ApiService,
    private functions: FunctionsService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.passwordForm = new PasswordForm();
    this.loadUserInfo();
  }

  loadUserInfo(): void
  {
    this.userProfile$ = this.service.getUserProfile();
    this.userProfile$.subscribe(data => {
      if (data.code === 1)
      {
        this.userProfile = data.details.data;
        this.name = this.userProfile.first_name;
        this.name += ' ' + this.userProfile.last_name;
        this.functions.log(this.userProfile.contact_phone);
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    });
  }

  saveProfile(): void
  {
    this.name = this.name.replace(/\s+$/, '');
    const tmpName = this.name.split(' ');
    if (tmpName.length <= 1)
    {
      this.functions.showAlertError('Digite seu nome completo.');
      return;
    }

    if (!this.userProfile.contact_phone || this.userProfile.contact_phone.length < 10)
    {
      this.functions.showAlertError('Telefone é obrigatorio');
      return;
    }

    this.userProfile.full_name = this.name;
    this.userProfile.first_name = '';
    this.userProfile.last_name = tmpName[tmpName.length - 1];
    for (const [k, v] of Object.entries(tmpName))
    {
      if (Number(k) < tmpName.length - 1)
      {
        this.userProfile.first_name += v + ' ';
      }
    }
    this.userProfile.first_name = this.userProfile.first_name.trim();

    this.userProfile$ = this.service.saveProfile(this.userProfile);
    this.userProfile$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    });
  }


  changePassword(): void
  {
    this.passwordForm$ = this.service.saveChangePassword(this.passwordForm);
    this.passwordForm$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
        this.passwordForm = new PasswordForm();
        this.modalService.dismissAll();
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    });
  }

  openModal(modal): void
  {
    this.modalService.open(modal);
  }

}
