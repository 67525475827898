import {Component, OnInit} from '@angular/core';
import {Order} from '../class/order';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ApiService} from '../services/api.service';
import {FunctionsService} from '../services/functions.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from '../services/login.service';
import {CartService} from '../services/new/cart.service';
import {ProfileService} from '../profile/profile.service';
import {CartModalComponent} from '../cart/cart-modal/cart-modal.component';
import {ReviewComponent} from '../review/review.component';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {

  orderActiveCount: string;
  reviewCount: string;

  service$: Observable<ApiResponse>;


  constructor(
      private service: ApiService,
      private functions: FunctionsService,
      public cartService: CartService,
      private modalService: NgbModal) { }

  ngOnInit(): void {
    this.orderActiveCount = this.functions.getItemLocal('orderActiveCount') ? this.functions.getItemLocal('orderActiveCount') : null;
    this.loadOrderReviewCount();
  }


  loadOrderReviewCount(): void
  {
    if (this.functions.getItemLocal('orderReview'))
    {
      const orderReview = JSON.parse(this.functions.getItemLocal('orderReview'));
      const tmp = orderReview.length;
      if (tmp === 0)
      {
        this.reviewCount = '';
      }
      else
      {
        this.reviewCount = tmp.toString();
      }
    }
    else
    {
      const orderReview = [];
      this.service$ = this.service.get('getOrders');
      this.service$.subscribe(data => {
        if (data.code === 1)
        {
          const orders: Order[] = data.details.data;
          for (const v of orders)
          {
            if (v.add_review)
            {
              orderReview.push(v);
            }
          }
          const tmp = orderReview.length;
          if (tmp === 0)
          {
            this.reviewCount = '';
          }
          else
          {
            this.reviewCount = tmp.toString();
          }
          this.functions.setItemLocal('orderReview', JSON.stringify(orderReview));
        }
      });
    }
  }

  openProfile(route): void
  {
    if (!this.functions.isLogin())
    {
      LoginService.loginEmitter.emit('openModal');
      return;
    }
    else
    {
      this.functions.redirect('profile', route);
      ProfileService.emitterCustomPage.emit(true);
    }
  }


  openCart(): void
  {
    this.modalService.open(CartModalComponent);
  }

  openReview(): void
  {
    this.modalService.open(ReviewComponent);
  }

}
