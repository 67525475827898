import { Component, OnInit } from '@angular/core';
import {UserProfile} from '../../class/user';
import {ApiService} from '../../services/api.service';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {Page, PageGet} from '../../class/page';
import {FunctionsService} from '../../services/functions.service';
import {ProfileService} from '../profile.service';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {timeInterval} from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  servicePages$: Observable<ApiResponse>;
  pagesGeneric$: Observable<ApiResponse>;

  routeChange$: Observable<boolean>;

  pagesGeneric: Page[];
  pageGet: PageGet[];
  userProfile: UserProfile;

  addressList: [];

  param: string;

  route = {
    account: true,
    address: false,
    order: false,
    customPage: false,
    contact: false
  };


  constructor(
      private service: ApiService,
      public functions: FunctionsService,
      private profileService: ProfileService,
      private router: ActivatedRoute,
      private loginService: LoginService)
  {
    this.addressList = [];
    this.param = this.router.snapshot.params[`param`];
  }

  ngOnInit(): void {
    this.pageGet = [];
    this.getPages();
    this.getPagesGeneric();
    this.loadClientInfo();

    this.routeChange$ = ProfileService.emitterCustomPage;
    this.routeChange$.subscribe(data => {
      this.functions.log('event profile');
      if (data)
      {
        this.functions.log(this.param);
        setTimeout(() => {
          this.param = this.router.snapshot.params[`param`];
          this.functions.log(this.param);
          if (this.param)
          {
            this.showComponent(this.param);
          }
        }, 500);

      }
    });

    if (this.param)
    {
      this.showComponent(this.param);
    }
  }


  loadClientInfo(): void
  {
    const localUser = this.functions.getItemLocal('userProfile');
    if (localUser)
    {
      this.userProfile = JSON.parse(localUser);
    }
    else
    {
      this.functions.log('user não existe necessita de requisição');
    }
  }

  getPages(): void
  {
    this.servicePages$ = this.service.get('getPages');
    this.servicePages$.subscribe(data => {
      if (data.code === 1)
      {
        this.pageGet = data.details.data;
      }
    });
  }

  getPagesGeneric(): void
  {
    this.pagesGeneric$ = this.service.get('getPagesGenericAll');
    this.pagesGeneric$.subscribe(data => {
      if (data.code === 1)
      {
        this.pagesGeneric = data.details.data;
      }
    });
  }

  showComponent(component, identify = null): void
  {
    this.functions.goToTop();
    if (identify)
    {
      this.functions.setItemLocal('pageCustom', identify);
      this.profileService.changePage();
    }


    for (const [k, v] of Object.entries(this.route))
    {
      if (k === component)
      {
        this.route[k] = true;
      }
      else
      {
        this.route[k] = false;
      }
    }

  }

  openLogin(): void
  {
    this.loginService.openLogin();
  }

  openSignUp(): void
  {
    this.loginService.openRegister();
  }

}
