import { Component, OnInit } from '@angular/core';
import {FunctionsService} from '../services/functions.service';

@Component({
  selector: 'app-profile-root',
  templateUrl: './profile-root.component.html',
  styleUrls: ['./profile-root.component.css']
})
export class ProfileRootComponent implements OnInit {


  addressList: [];
  ordersDone: [];
  orders: [];

  constructor(private functions: FunctionsService)
  {
    this.addressList = [];
    this.ordersDone = [];
    this.orders = [];
  }

  ngOnInit(): void
  {
  }

}
