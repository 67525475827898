<app-nav-header-payment></app-nav-header-payment>
<div class="mt-menu-header">

  <div class="d-none fixed-top">
    <div class="bg-primary border-bottom p-3 d-flex align-items-center">
        <a href="javascript:;" routerLink="/home"><i class="feather-arrow-left h3 font-weight-bold text-white mr-2"></i></a>
        <h5 class="font-weight-bold m-0 text-white">Confirmação de pedido</h5>
    </div>
  </div>

  <!-- checkout -->
  <div class="container position-relative">
    <div class="py-4 row mb-5 mt-5 mt-md-2 mb-sm-0">
      <div class="col-md-8 col-sm-7 mb-3">
        <div class="d-flex flex-column">
          <div class="p-3">
            <div class="row" ngbRadioGroup name="transaction_type"  [(ngModel)]="transactionType" (change)="transactionTypeChange($event)">
              <div class="col-4 option-delivery mt-1"> ESCOLHA UMA OPÇÃO</div>
              <div class="col-md-4 col-6 check_custom-group" *ngFor="let serv of this.merchantInfo.services | keyvalue" >
                <input ngbButton class="check_custom" id="{{serv.key}}" type="radio" [value]="serv.key" *ngIf="!cartService.hiddenService[serv.key]">
                <label ngbButtonLabel for="{{serv.key}}" class="w-100 text-center" *ngIf="!cartService.hiddenService[serv.key]"> {{serv.value.toString() | uppercase}}</label>

                <span class="badge badge-warning small d-block" *ngIf="cartService.hiddenService[serv.key]">
                  {{serv.key === 'delivery' ? 'ENTREGAS A PARTIR DE' : 'RETIRADAS A PARTIR DE'}}
                </span>
                <small *ngIf="cartService.hiddenService[serv.key]">{{serv.key === 'delivery' ? cartService.hiddenService?.deliveryMsg : cartService.hiddenService?.pickupMsg}}</small>
                <small *ngIf="cartService.hiddenService?.deliveryHours || cartService.hiddenService?.pickupHours">{{serv.key === 'delivery' ? cartService.hiddenService?.deliveryHours : cartService.hiddenService?.pickupHours}}</small>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="mb-3 rounded shadow-sm bg-white overflow-hidden">
            <div class="bg-white p-3 min-h-card-215">
              <div class="d-flex flex-column" *ngIf="cartService.details.transaction_type === 'delivery'">
                <h6 class="mb-3 font-weight-bold d-flex">Endereço de ENTREGA
                  <small class="ml-auto">
                    <button class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" (click)="openModalAddress(modalAddress)" >
                      <i class="feather-plus font-weight-bold"></i>
                      <span class="text-center"> ALTERAR</span>
                    </button>
                  </small>
                </h6>

                <div class="row" *ngIf="cartService.cartDetails.street">
                  <div class="custom-control col-lg-6 custom-radio mb-3 position-relative border-custom-radio">
                    <input type="radio" [id]="cartService.cartDetails.number" name="customRadioInline1" class="custom-control-input" checked>
                    <label class="custom-control-label w-100" [for]="cartService.cartDetails.number">
                      <div>
                        <div class="p-3 bg-white rounded shadow-sm w-100">
                          <div class="d-flex align-items-center mb-2">
                            <p class="mb-0">{{cartService.cartDetails.location_name}}</p>
                            <!--<p class="mb-0 badge badge-success ml-auto"><i class="icofont-check-circled"></i> Padrão</p>-->
                          </div>
                          <h5 class="mb-2 font-weight-bold">{{cartService.cartDetails.street}}, {{cartService.cartDetails.number}}</h5>
                          <p class="small text-muted m-0">{{cartService.cartDetails.bairro}} {{cartService.cartDetails.complemento ? ' - ' + cartService.cartDetails.complemento : ''}} - {{cartService.cartDetails.city}} - {{cartService.cartDetails.state}}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-6" *ngIf="cartService.deliveryEstimation" >

                    <div class="delivery_address mt-3">
                      <p class="d-flex mb-0 mb-sm-1">
                        <span class="pt-2">Taxa de Entrega: <b class="ml-1 h6 text-weight-bold">{{cartService.deliveryFee | prettyPrice}}</b></span>
                        <i class="feather-check h3 text-warning ml-1 font-weight-bold mb-0"></i>
                      </p>
                      <p class="text-muted" *ngIf="cartService.deliveryEstimation">  Tempo aprox. de entrega:
                        <span class="font-weight-bold "> {{cartService.deliveryEstimation}} </span>
                      </p>
                    </div>

                  </div>
                </div>

              </div>

              <div class="d-flex flex-column" *ngIf="cartService.details.transaction_type === 'pickup'">
                <h6 class="mb-3 font-weight-bold d-flex">Endereço para <span class="text-danger font-weight-bold ml-2"> RETIRAR</span> </h6>

                <div class="row" *ngIf="cartService.cartDetails.street">
                  <div class="custom-control col-lg-12 custom-radio mb-3 position-relative border-custom-radio">
                    <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" checked>
                    <label class="custom-control-label w-100" for="customRadioInline1">
                      <div>
                        <div class="p-3 bg-white rounded shadow-sm w-100 d-flex">
                          <div class="brand-wrap mr-3">
                            <img alt="#" class="img-fluid " [src]="merchantInfo.optionsMerchant.logo_app">
                          </div>
                          <div>
                            <h5 class="mb-2 font-weight-bold">{{merchantInfo.street}}, {{merchantInfo.number}}</h5>
                            <p class="small text-muted m-0">{{merchantInfo.bairro}} - {{merchantInfo.city}} - {{merchantInfo.state}}</p>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-6">

                    <div class="delivery_address mt-3">
                      <p class="d-flex mb-0 mb-sm-1">
                      </p>
                      <p class="text-muted" *ngIf="cartService.deliveryEstimation">  Tempo aprox. de preparo: <span class="font-weight-bold ">{{cartService.deliveryEstimation}}</span></p>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div #divPaymentForm id="__payment_form" class="mb-3 rounded shadow-sm bg-white overflow-hidden">
            <div class="bg-white p-3">
              <div class="d-flex flex-column">
                <h6 class="mb-0 mb-sm-3 font-weight-bold d-flex">Forma de pagamento
                  <small class="ml-auto">
                    <button class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" (click)="openModal(modalPaymentList)">
                      <i class="feather-plus font-weight-bold"></i>
                      <span class="text-center"> ESCOLHER</span>
                    </button>
                  </small>
                </h6>

                <small class="text-danger" *ngIf="paymentError">Selecione a forma de pagamento</small>

                <div *ngIf="checkout.payForm.payment_provider">
                  <p [innerHTML]="paySelect" class="m-0"></p>
                </div>

              </div>
            </div>
          </div>

          <div class="mb-3 rounded shadow-sm bg-white overflow-hidden" *ngIf="!cartService.loading">
            <div class="bg-white p-3">
              <!--APLICAR CUPOM-->
              <div class="input-group-sm mb-2 input-group" *ngIf="!cartService.cartDetails.voucher_details">
                <div class="mr-2 mr-sm-3 pt-1">Cupom</div>
                <input #voucher placeholder="Insira um cupom" type="text" class="form-control">
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" (click)="applyVoucher(voucher.value)">
                    <i class="feather-percent"></i> APLICAR
                  </button>
                </div>
              </div>
              <!--CUPOM APLICADO-->
              <p *ngIf="cartService.cartDetails.voucher_details">
                Cupom aplicado:
                <span class="text-muted">
                  <b>{{cartService.cartDetails.voucher_details.voucher_name}}</b>
                </span>
                <button type="button" class="btn btn-outline-warning small ml-2 px-1 py-0 m-0" (click)="removeVoucher()">
                  <i class="feather-x font-weight-bold"></i>
                </button>
              </p>
              <div class="mb-0 input-group">
                <div class="input-group-prepend"><span class="input-group-text"><i class="feather-message-square"></i></span></div>
                <textarea placeholder="Observação para este pedido" aria-label="With textarea" class="form-control" name="delivery_instruction" [(ngModel)]="checkout.payForm.delivery_instruction"></textarea>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!---- CARRINHO LATERAL -->
      <div class="col-md-4 col-sm-5 mb-5 mb-sm-0">
        <app-cart-payment></app-cart-payment>
        <div class="text-center mt-sm-3">
          <a href="javascript:;" routerLink="/home" class="btn btn-sm btn-outline-secondary">
            <i class="feather-plus font-weight-bold "></i>
            ADICIONAR MAIS ITENS
          </a>
        </div>
        
      </div>

      
    </div>
  </div>
</div>

<ng-template #modalPaymentList let-modal>
  <div class="modal-header shadow">
    <h5 class="m-0">Formas de Pagamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close"></button>
  </div>

  <div class="modal-body modal-body-70vh p-3">
    <p>Escolha uma das opções aceitas por esse estabelecimento</p>
    <div class="filter">
      <h5>Pagamento na entrega</h5>
      <div class="custom-control  border-bottom px-0 custom-radio" *ngFor="let pay of paymentList">
        <input
                #p
                type="radio"
                id="_{{pay.payment_name}}"
                name="address"
                [checked]="localPayment === pay.payment_code + '|' + pay.payment_name + '|' + (pay.img ? pay.img : '')"
                class="custom-control-input"
                (click)="selectPayment(pay.payment_code, pay.payment_name, pay.img)">
        <label class="custom-control-label py-3 w-100 px-3" for="_{{pay.payment_name}}"><img *ngIf="pay.img" src="{{pay.img}}" class="img-fluid" width="20"> {{pay.payment_name}}</label>

        <div class="d-flex align-items-center">
          <div class="form-group" style="width: 90px;" *ngIf="pay.payment_code === 'cod'" [style.display]="orderChange ? '' : 'none'">
            <label>Troco?</label>
            <input type="text" currencyMask class="form-control" placeholder="R$        ,      " [(ngModel)]="checkout.payForm.order_change">
          </div>
          <div class="form-group" *ngIf="pay.payment_code === 'cod'" [style.display]="orderChange ? '' : 'none'">
            <label class="form-label"></label>
            <div class="custom-checkbox custom-control custom-checkbox-charge" >
              <input #dontChargeEl id="dontCharge" type="checkbox" class="custom-control-input" name="dontCharge" [(ngModel)]="dontCharge">
              <label for="dontCharge" class="custom-control-label">Não preciso de troco</label>
            </div>
          </div>
          

        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer p-0">
    <div class="col-6 m-0 p-0">
      <button class="btn btn-lg btn-block" (click)="modal.dismiss('Cross click')">Voltar</button>
    </div>
    <div class="col-6 m-0 p-0">
      <button class="btn btn-primary btn-lg btn-block" (click)="savePayment()">ESCOLHER</button>
    </div>
  </div>
</ng-template>



<ng-template #modalAddress let-modalAddress>
  <app-address></app-address>
</ng-template>



<!--
<div class="osahan-payment" *ngIf="service$ | async as apiResponse ; else loading">

  <div class="mt-5 p-0">
    <div class="bg-primary shadow p-3">
       <a class="hc-nav-back text-white" routerLink="/cart"><span><i class="feather-arrow-left-circle"></i></span></a>
       <h4 class="m-0 pt-1 text-white pl-5">CONFIRMAR PEDIDO</h4>
    </div>
  </div>

  &lt;!&ndash; checkout ADDRESS &ndash;&gt;
  <form #formPayNow='ngForm' (ngSubmit)="validPayNow(formPayNow)">
    <input type="hidden" name='transaction_type' [(ngModel)]="payForm.transaction_type" required>
    <input type="hidden" name="payment_provider" [(ngModel)]="payForm.payment_provider" required>
    <input type="hidden" name="delivery_date" [(ngModel)]="payForm.delivery_date" required>
    <input type="hidden" name="order_change" [(ngModel)]="payForm.order_change" required>
    <input type="hidden" name="delivery_asap" [(ngModel)]="payForm.delivery_asap" required>

    <div class="p-3 mt-6">
      <div class="card-item mb-3" *ngIf="details.transaction_type === 'delivery'">
        <div id="Address" class="osahan-cart-item-profile bg-white rounded shadow p-3" [class.error]="addressError">
          <div class="d-flex flex-column">
            <h6 class="mb-2 font-weight-bold d-flex">Endereço de entrega
              <small class="text-danger" *ngIf="addressError">Selecione um endereço para entrega</small>
              <small class="ml-auto">
                <button class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" [routerLink]="['/address', 'payment']" >
                  <i class="feather-plus font-weight-bold"></i>
                  <span class="text-center"> ALTERAR</span>
                </button>
              </small>
            </h6>

            <div class="custom-control custom-radio mb-2 px-0" *ngIf="cartDetails.street">
              <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" checked="">
              <label class="custom-control-label border osahan-check p-3 w-100 rounded border-primary" for="customRadio1">
                <p class="m-0"> <b><i class="feather-home mr-2"></i>{{cartDetails.location_name}}</b> </p>
                <p class="mb-0 pl-4">{{cartDetails.street}}, {{cartDetails.number}} <br>
                  <small>{{cartDetails.bairro}} - {{cartDetails.city}} - {{cartDetails.state}}</small></p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card-item" >
        <div id="Payments" class="card-payment bg-white rounded shadow p-3" [class.error]="paymentError">
          <div class="d-flex flex-column">
            <h6 class="mb-1 font-weight-bold d-flex">Forma de pagamento

              <small class="ml-auto">
                <a class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" (click)="openModal(modalPaymentList)" >
                  <i class="feather-dollar-sign font-weight-bold"></i>
                  <span class="text-center"> ESCOLHER</span>
                </a>
              </small>
            </h6>
            <small class="text-danger" *ngIf="paymentError">Selecione a forma de pagamento</small>
          </div>
          <div *ngIf="payForm.payment_provider">
            <p [innerHTML]="paySelect" class="m-0"></p>
          </div>
        </div>
      </div>

    </div>

  <div class="px-3 mb-7">
    &lt;!&ndash; checkout PAYMENT TYPE &ndash;&gt;

    &lt;!&ndash; checkout CUPOM &ndash;&gt;
    <div class="mb-3 shadow bg-white rounded p-3 mt-3 clearfix">
      <div class="input-group-sm mb-2 input-group">
        <input #voucher placeholder="Adicione o código do cupom" type="text" class="form-control">
        <div class="input-group-append">
          <button id="button-addon2" type="button" class="btn btn-primary" (click)="applyVoucher(voucher.value)">
            <i class="feather-percent"></i> Aplicar cupom
          </button>
        </div>
      </div>
      <div class="mb-0 input-group">
        <div class="input-group-prepend"><span class="input-group-text"><i class="feather-message-square"></i></span></div>
        <textarea placeholder="Alguma observação neste pedido?" aria-label="With textarea" class="form-control" name="delivery_instruction" [(ngModel)]="payForm.delivery_instruction"></textarea>
      </div>
    </div>

    &lt;!&ndash; checkout RESUME &ndash;&gt;
    <h5 class="mt-3 pt-2 pl-3 font-weight-bold">Resumo do pedido</h5>
    <div class="bg-white rounded shadow pt-2 mb-3">
      <div class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom" *ngFor="let item of cartData.item | keyvalue ; let i = index">
        <div class="media">
          <div class="mr-2"> {{item.value.qty}} x</div>
          <div class="media-body">
            <p class="m-0">{{item.value.item_name}}<br>
              <small class="text-muted" *ngIf="item.value.qty > 1">{{item.value.qty}} x {{functions.prettyPrice(item.value.discounted_price)}}</small>
            </p>
          </div>
        </div>
        <div>
          <p class="text-gray mb-0 float-right ml-2 text-muted">{{functions.prettyPrice(item.value.discounted_price * item.value.qty)}}</p>
        </div>
      </div>
      <div class=" p-3 clearfix">
        <p class="mb-1">Sub Total <span class="float-right text-dark">{{functions.prettyPrice(cartData.total.subtotal)}}</span></p>
        <p class="mb-1" *ngIf="details.transaction_type === 'delivery'">Taxa de entrega <span class="text-info ml-1"><i class="icofont-info-circle"></i></span><span class="float-right text-dark">{{cartDetails.delivery_fee > 0 ? functions.prettyPrice(cartDetails.delivery_fee) : 'Selecione o endereço'}}</span></p>
        <p class="mb-1 text-success" *ngIf="cartData.total.less_voucher">Descontos<span class="float-right text-success" [textContent]="'- ' + functions.prettyPrice(cartData.total.less_voucher)"></span></p>
        <hr>
        <h6 class="font-weight-bold mb-3">TOTAL A PAGAR <span class="float-right" [textContent]="functions.prettyPrice(cartData.total.total)"></span></h6>
      </div>
    </div>
  </div>

  &lt;!&ndash; checkout CONFIRM&ndash;&gt;
    <div class="">
      <button class="btn btn-primary btn-block btn-lg" type="submit">
        CONFIRMAR PEDIDO <span class="font-weight-light  px-3">{{functions.prettyPrice(cartData.total.total)}}</span>
      </button>
    </div>
  </form>
  &lt;!&ndash; END PAYMENT OPTIONS - VIEWS FOR APP  &ndash;&gt;
</div>

&lt;!&ndash; Modal &ndash;&gt;


<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
-->
