import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {ApiResponse} from '../../class/apiResponse';

import {Address, CEPApi} from '../../class/address';
import {FunctionsService} from '../../services/functions.service';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartService} from '../../services/cart.service';
import {AddressService} from '../../services/address.service';
import {ModalService} from '../../services/new/modal.service';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  @ViewChild('modalAddressEdit', { static: false }) private modalAddress;

  addressList: Address[];
  addressEdit: Address;
  cepApi: CEPApi;
  cep$: Observable<CEPApi>;
  loadAddress$: Observable<ApiResponse>;
  saveAddress$: Observable<ApiResponse>;
  deleteAddress$: Observable<ApiResponse>;

  address$: Observable<boolean>;

  divMap: HTMLElement;
  map: google.maps.Map;
  autoCompleteService: google.maps.places.AutocompleteService;
  geoCoder: google.maps.Geocoder;
  marker: google.maps.Marker;
  locationName: string;

  btnLoadinSave: boolean;
  step: number;
  constructor(
    private service: ApiService,
    private functions: FunctionsService,
    private iziToast: Ng2IzitoastService,
    private ngbModalService: NgbModal,
    private modalService: ModalService)
  {
    this.address$ = new Observable<boolean>();
  }

  ngOnInit(): void {
    this.btnLoadinSave = false;
    this.addressEdit = new Address();
    this.address$ = AddressService.addressEmitter;
    this.address$.subscribe(data => {
      if (data)
      {
        this.loadAddress();
      }
    });
    this.loadAddress();
  }

  openModalAddress(): void
  {
    this.modalService.openAddress();
  }

  routerBack(step): void
  {
    this.functions.log(step);
    this.functions.log(typeof step);
    if (step === 2)
    {
      this.step = 1;
    }
    else
    {
      this.functions.redirect('profile');
    }
  }

  loadAddress(): void
  {
    this.step = 1;
    this.loadAddress$ = this.service.getAddressBookList();

    this.loadAddress$.subscribe(data => {
      if (data.code === 1)
      {
        this.addressList = data.details.data;
        this.functions.log(this.addressList);
      }
    });
  }

  optionsAddress(id): void
  {
    for (const [k, v] of Object.entries(this.addressList))
    {
      if (v.id === id)
      {
        this.addressEdit = v;
        this.locationName = this.addressEdit.location_name;
        break;
      }
    }
    this.ngbModalService.open(this.modalAddress);
    this.initMap();
    this.map.setCenter(new google.maps.LatLng(Number(this.addressEdit.latitude), Number(this.addressEdit.longitude)));
    this.marker.setPosition({lat: Number(this.addressEdit.latitude), lng: Number(this.addressEdit.longitude)});

  }

  getCep(cep): void
  {
    this.cep$ = this.service.getAddressCEP(cep);
    this.cep$.subscribe(data => {
      this.cepApi = data;
      this.addressEdit.street = this.cepApi.logradouro;
      this.addressEdit.number = '';
      this.addressEdit.bairro = this.cepApi.bairro;
      this.addressEdit.city = this.cepApi.localidade;
      this.addressEdit.state = this.cepApi.uf;

    });
  }

  initMap(): void
  {
    const input = document.getElementById('pac-input') as HTMLInputElement;
    this.divMap = document.getElementById('map');

    this.functions.log(this.addressEdit);

    this.map = new google.maps.Map(this.divMap, {
      zoom: 15,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false
    });

    this.geoCoder = new google.maps.Geocoder();
    this.marker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      map: this.map,
      draggable: true,
    });
    this.autoCompleteService = new google.maps.places.AutocompleteService();

    /*EVENT DRAGEND MARKER*/
    google.maps.event.addListener(this.marker, 'dragend', (ev) => {
      this.geoCoder.geocode({location: new google.maps.LatLng(ev.latLng.lat(), ev.latLng.lng())}, (r, s) => {
        if (s === 'OK')
        {
          this.addressEdit.address = r[0].formatted_address;

          this.addressEdit.latitude = r[0].geometry.location.lat().toString();
          this.addressEdit.longitude = r[0].geometry.location.lng().toString();
          if (isNaN(Number(r[0].address_components[0].long_name)))
          {
            this.addressEdit.street = r[0].address_components[0].long_name;
            this.addressEdit.bairro = r[0].address_components[1].long_name;
            this.addressEdit.city = r[0].address_components[2].long_name;
            this.addressEdit.state = r[0].address_components[3].long_name;
            this.addressEdit.zipcode = r[0].address_components[5].long_name;

          }
          else
          {
            this.addressEdit.number = r[0].address_components[0].long_name;
            this.addressEdit.street = r[0].address_components[1].long_name;
            this.addressEdit.bairro = r[0].address_components[2].long_name;
            this.addressEdit.city = r[0].address_components[3].long_name;
            this.addressEdit.state = r[0].address_components[4].long_name;
            this.addressEdit.zipcode = r[0].address_components[6].long_name;
          }
          this.functions.log(this.addressEdit);
        }
      });
    });
  }

  /*CHANGE DEFAULT ADDRESS*/
  checkDefault(asDefault): void
  {
    this.functions.log(asDefault.checked);
    if (asDefault.checked)
    {
      this.addressEdit.as_default = '2';
    }
    else
    {
      this.addressEdit.as_default = '1';
    }
  }

  /*CHANGE LOCATION NAME*/
  checkLocation(input): void
  {
    this.addressEdit.location_name = input.value;
  }

  saveAddressDefault(id): void
  {
    for (const [k, v] of Object.entries(this.addressList))
    {
      if (v.id === id)
      {
        this.addressEdit = v;
        break;
      }
    }
    this.addressEdit.as_default = '2';
    this.saveAddress();
  }

  changeLocation(e): void
  {
    this.addressEdit.location_name = e.target.value;
    this.functions.log(this.addressEdit);
  }

  saveAddress(): void
  {
    this.saveAddress$ = this.service.saveAddress(this.addressEdit);
    this.btnLoadinSave = true;
    this.saveAddress$.subscribe(data => {
      this.functions.log(data);
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
        this.ngbModalService.dismissAll();
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
      this.functions.log(error);
      this.functions.showAlertError('Erro com servidor!', 'Não foi possivel alterar as informações');
    }, () => {
      this.btnLoadinSave = false;
      this.functions.goToTop();
      this.loadAddress();
    });
  }

  deleteAddress(id, address): void
  {
    const ad = {id, address};
    this.iziToast.question({
      timeout: 20000,
      zindex: '50000',
      close: false,
      id: 'question',
      title: 'Deseja deletar esse endereço:',
      message: address,
      position: 'center',
      color: 'red',
      buttons: [
        ['<button><b>Deletar</b></button>', (instance, toast) => {

          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          this.deleteAddress$ = this.service.deleteAddress(ad);
          this.deleteAddress$.subscribe(data => {
            this.functions.log(data);
            if (data.code === 1)
            {
              this.functions.showAlertSuccess(data.msg);
            }
            else
            {
              this.functions.showAlertError(data.msg);
            }
          }, error => {
            this.functions.log(error);
          }, () => {
            this.ngbModalService.dismissAll();
            this.functions.goToTop();
            this.loadAddress();
          });

        }, true],
        ['<button>Cancelar</button>', (instance, toast) => {

          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }],
      ]
    });
  }

}
