import { Component, OnInit } from '@angular/core';
import {Page} from '../../class/page';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {FunctionsService} from '../../services/functions.service';
import {ProfileService} from '../profile.service';

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['./custom-page.component.css']
})
export class CustomPageComponent implements OnInit {


  page: Page;

  service$: Observable<ApiResponse>;
  params: string;

  visit: boolean;
  routeBack: string;

  customPage$: Observable<any>;

  constructor(
      private service: ApiService,
      private activeRoute: ActivatedRoute,
      private functions: FunctionsService)
  {
    this.params = this.functions.getItemLocal('pageCustom');
  }

  ngOnInit(): void {
    this.customPage$ = ProfileService.emitterCustomPage;
    this.customPage$.subscribe(data => {
      if (data)
      {
        this.functions.log('event Emitter');
        this.params = this.functions.getItemLocal('pageCustom');
        this.loadPage();
      }
    });
    this.loadPage();
  }


  loadPage(): void
  {
    if (isNaN(Number(this.params)))
    {
      this.service$ = this.service.getPagesGeneric(this.params);
      this.service$.subscribe(data => {
        this.functions.log(data);
        if (data.code === 1)
        {
          this.page = data.details.data;
        }
        else
        {
          this.functions.showAlertError('Não foi possivel carregar a página');
          this.functions.redirect('profile');
        }
      }, error => {

      }, () => {

      });
    }
    else
    {
      this.functions.log(this.params);
      this.service$ = this.service.getPagesById(this.params);
      this.service$.subscribe(data => {
        this.functions.log(data);
        if (data.code === 1)
        {
          this.page = data.details.data;
        }
        else
        {
          this.functions.showAlertError('Não foi possivel carregar a página, tente novamente mais tarde');
          this.functions.redirect('profile');
        }
      });
    }
  }

}
