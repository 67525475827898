
<div class="rounded" *ngIf="service$ | async; else loading">
    <div id="intro" class="mb-4">
        <!-- Title -->
        <div class="mb-3">
            <h2 class="h5 text-primary">{{page.title}}</h2>
        </div>
        <!-- End Title -->
        <p [innerHTML]="page.content"></p>
    </div>
</div>


<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
