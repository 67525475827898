import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../services/api.service';
import {Order} from '../class/order';
import {ActivatedRoute} from '@angular/router';

import {SendReview} from '../class/review';
import {FunctionsService} from '../services/functions.service';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {NgbActiveModal, NgbModal, NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgModel} from '@angular/forms';
import {CartService} from '../services/cart.service';
import {ReviewService} from '../services/new/review.service';

@Component({
  selector: 'app-review-add',
  templateUrl: './review-add.component.html',
  styleUrls: ['./review-add.component.css']
})
export class ReviewAddComponent implements OnInit, OnDestroy {

  @Input('modal') modal;

  order: Order;
  id: string;
  reviewSend: SendReview;

  erroRating: boolean;
  erroComent: boolean;

  service$: Observable<ApiResponse>;

  loading: boolean;
  disableBtn: boolean;

  constructor(
    private service: ApiService,
    private routerActive: ActivatedRoute,
    public functions: FunctionsService,
    public activeModal: NgbActiveModal,
    private configRating: NgbRatingConfig,
    private modalService: NgbModal,
    private reviewService: ReviewService)
  {
    this.order = new Order();
    this.configRating.readonly = false;
    this.id = this.reviewService.reviewId;
    this.loading = false;
  }

  ngOnInit(): void {
    this.reviewSend = new SendReview();
    this.reviewSend.rating = 1;
    this.getOrder();
  }

  ngOnDestroy(): void
  {
    this.reviewService.reviewId = '';
  }

  onClickStar(e): void
  {
    this.erroRating = false;
    this.reviewSend.rating = e.rating;
  }

  getOrder(): void
  {
    this.loading = true;
    this.service$ = this.service.getOrderById(this.id);
    this.service$.subscribe(data => {
      this.functions.log(data);
      this.order = data.details.data;
    }, error => {
      this.functions.log(error);
    }, () => {
      this.loading = false;
    });

    /*const ordersReview = this.functions.getItemLocal('orderReview') ? JSON.parse(this.functions.getItemLocal('orderReview')) : '';
    if (ordersReview)
    {
      for (const v of ordersReview)
      {
        if (v.order_id === this.id)
        {
          this.order = v;
          break;
        }
      }
      console.log(this.order);
    }*/
  }

  sendReview(): void
  {
    this.erroComent = false;
    this.erroRating = false;
    this.reviewSend.review_order_id = this.order.order_id;
    this.functions.log(this.reviewSend);
    if (this.reviewSend.rating === 0)
    {
      this.erroRating = true;
      return;
    }
    if (!this.reviewSend.review && this.reviewSend.rating < 5)
    {
      this.erroComent = true;
      return;
    }

    this.service$ = this.service.addReview(this.reviewSend);
    this.disableBtn = true;
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
        this.modalService.dismissAll();
        CartService.emitterAddCart.emit('openModalReview');
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
      this.functions.showAlertErroServer();
    }, () => {
      this.disableBtn = false;
    });
  }
}
