export class Review
{
  id: string;
  avatar: string;
  can_edit: boolean;
  client_name: string;
  date: string;
  rating: string;
  review: string;
  reply: Reply;
}

export class Reply
{
  date: string;
  reply_from: string;
  review: string;
}

export class SendReview
{
  review_order_id: string;
  rating: number;
  review: string;
}
