import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  static emitterCustomPage = new EventEmitter<boolean>();


  constructor() { }


  changePage(): void
  {
    ProfileService.emitterCustomPage.emit(true);
  }
}
