import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {User} from '../class/user';
import {ApiService} from '../services/api.service';
import {FunctionsService} from '../services/functions.service';
import {Merchant} from '../class/merchant';
import {Observable} from 'rxjs';
import {LoginService} from '../services/login.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SignupComponent} from '../signup/signup.component';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public user: User;

  validate: boolean;

  passTmp: string;
  visit: boolean;
  route: string;
  merchant: Merchant;
  login$: Observable<string>;

  btnDisabled: boolean;

  constructor(
    private routeActive: ActivatedRoute,
    public service: ApiService,
    private functions: FunctionsService,
    private loginService: LoginService,
    private ngbModalService: NgbModal,
    public activeModal: NgbActiveModal)
  {
    this.btnDisabled = false;
    this.validate = false;
    this.user = new User();
  }

  ngOnInit(): void {
    this.getMerchantInfo();
    this.visit = this.functions.isVisit();
    this.login$ = LoginService.loginEmitter;
    this.login$.subscribe(data => {
      if (data === 'loginDisabledBtn')
      {
        this.btnDisabled = false;
      }
    });
  }

  login(): void
  {
    this.validate = true;
    this.functions.log('login');
    this.functions.log(this.user.username);
    if (!this.user.username)
    {
      this.validate = true;
      return;
    }

    if (!this.passTmp)
    {
      this.validate = true;
      return;
    }

    this.user.password = this.passTmp;
    this.btnDisabled = true;
    this.service.responseLogin(this.user, '');

  }

  getMerchantInfo(): void
  {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
    }
  }

  openRegister(): void
  {
    this.ngbModalService.open(SignupComponent);
  }

  openForgotPass(): void
  {
    this.ngbModalService.open(ForgotPasswordComponent);
  }
}
