<app-nav-header *ngIf="service.color$" [style.display]="hide ? 'none' : ''"></app-nav-header>
<router-outlet *ngIf="service.color$"></router-outlet>


<app-footer-bar *ngIf="!hide"></app-footer-bar>
<app-footer *ngIf="service.color$"></app-footer>

<app-spinner *ngIf="!service.color$"></app-spinner>

<!-- Footer body html -->



